<div class="component-frame">
  <div class="main-container">
    <div class="menu-sidebar">
      <button pButton label="Cmd & Ctrl" id="cmd_ctrl" class="menu-btn" [matMenuTriggerFor]="menu"
              (click)="getCmdAndCtrlOptions()" [disabled]="isAuLogin"></button>
      <button pButton label="Permissions" id="permissions" class="menu-btn" [matMenuTriggerFor]="menu"
              (click)="getPermissionsOptions()" [disabled]="isAuLogin"></button>
      <button pButton label="Command Info" id="command_info" class="menu-btn" [matMenuTriggerFor]="menu"
              (click)="getCommandInfoOptions()"></button>
      <button pButton label="OEM Commands" id="oem_commands" class="menu-btn" [matMenuTriggerFor]="menu"
              (click)="getOEMCommandsOptions()" [disabled]="!isAuLogin"></button>
      <button pButton label="Smart Charge" id="smart_charge" class="menu-btn" [matMenuTriggerFor]="menu"
              (click)="getSmartChargeOptions()" [disabled]="isAuLogin"></button>
      <button pButton label="FVS" id="video_streaming" class="menu-btn"
              (click)="showVideoStreamDialog()" [disabled]="isAuLogin"></button>
      <button pButton label="EV" id="ev" class="menu-btn" [matMenuTriggerFor]="menu"
              (click)="getEVOptions()" [disabled]="isAuLogin"></button>
      <button pButton label="Geofence" id="geofence" class="menu-btn" [matMenuTriggerFor]="menu"
              (click)="getGeofenceOptions()" [disabled]="isAuLogin"></button>
      <button pButton label="FB5" id="fb5" class="menu-btn" [matMenuTriggerFor]="menu"
              (click)="getFB5Options()" [disabled]="isAuLogin"></button>
    </div>

    <div id="results-container">
      <textarea id="results" disabled [(ngModel)]="results"></textarea>
      <div id="clear-btn-wrapper">
        <button pButton label="Clear Responses" id="clear-btn" class="control-rounded" (click)="clearResults()"></button>
      </div>
    </div>
  </div>

  <div class="progress-spinner" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>

<mat-menu #menu="matMenu" class="custom-menu">
    <button *ngFor="let menu of menuOption" pButton class="submenu-btn" (click)="handleClick(menu.value)" label="{{ menu.label }}"></button>
</mat-menu>

<p-dialog [(visible)]="telemetrySettingsDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Telemetry Settings" (onHide)="closeTelemetrySettings()">
  <div class="form-field-row-item"></div>
  <div class="radio-button-wrapper">
    <p-radioButton name="telemetry-settings" value="ENABLE" label="Enable (CCS)"
                   [(ngModel)]="telemetrySettings"
                   class="command-settings-radio-button"></p-radioButton>
    <p-radioButton name="telemetry-settings" value="DISABLE" label="Disable (CCS)"
                   [(ngModel)]="telemetrySettings"
                   class="command-settings-radio-button"></p-radioButton>
    <p-radioButton name="telemetry-settings" value="ENABLE_SANS_LOCATION" label="Enable Only Vehicle Info (CCS)"
                   [(ngModel)]="telemetrySettings"
                   class="command-settings-radio-button "></p-radioButton>
  </div>
  <div class="form-field-row-item"></div>
  <span class="command-dialog-button-wrapper">
      <button class="command-dialog-finish-button" pButton label="Finish" class="control-rounded" (click)="handleSetTelemetrySettings()"></button>
    </span>
</p-dialog>

<p-dialog [(visible)]="authorizeTMCVinDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Authorize TMC Vin" (onHide)="closeAuthorizeTMCVin()">
  <span id="authorize-vin-wrapper" class="form-field-row">
     <label class="label-text-right form-field-row-item">{{authorizeTMCVinOdometer.fieldName}}</label>
     <p-inputNumber type="text" inputStyleClass="control-rounded control-border form-field-row-item {{authorizeTMCVinOdometer.isMissing ? 'field-error' : ''}}"
                   [(ngModel)]="authorizeTMCVinOdometer.value"
                   (focusout)="checkMissingAuthorizeTMCVinOdometer()" [min]="0" [minFractionDigits]="0" [maxFractionDigits]="3"></p-inputNumber>
     <span class="form-field-row-item">
        <small *ngIf="authorizeTMCVinOdometer.isMissing"
               class="field-error-message">{{authorizeTMCVinOdometer.errorMessage}}</small>
     </span>
  </span>
  <span class="command-dialog-button-wrapper">
      <button class="command-dialog-finish-button" pButton label="Finish" class="control-rounded" (click)="handleAuthorizeTMCVin()"></button>
    </span>
</p-dialog>

<p-dialog [(visible)]="scMinSOCDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Smart Charge Min SOC" (onHide)="closeSCMinSOCDialog()">
  <span class="form-field-row space-bottom">
     <label class="label-text-right form-field-row-item">{{scMinSOC.fieldName}}</label>
     <p-inputNumber type="text" inputStyleClass="control-rounded control-border form-field-row-item {{scMinSOC.isMissing ? 'field-error' : ''}}"
                    [(ngModel)]="scMinSOC.value"
                    (focusout)="checkMissingSCMinSOC()" [min]="0" [max]="100" [minFractionDigits]="0" [maxFractionDigits]="2"></p-inputNumber>
    <span class="form-field-row-item">
        <small *ngIf="scMinSOC.isMissing"
               class="field-error-message">{{scMinSOC.errorMessage}}</small>
     </span>
  </span>
  <span class="command-dialog-button-wrapper">
      <button class="command-dialog-finish-button" pButton label="Save" class="control-rounded" (click)="handleSCMinSOC()"></button>
  </span>
</p-dialog>

<p-dialog [(visible)]="scSaveBDSettingDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Save Bidirectional Setting" (onHide)="closeSaveBDSettingDialog()">
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{saveBDSetting['mode'].fieldName}}</label>
    <p-dropdown [options]="modeOptions" class="form-field-row-item"
                styleClass="control-rounded {{saveBDSetting['mode'].isMissing ? 'field-error' : ''}}"
                (onHide)="checkMissingBDSetting('mode')"
                (onChange)="checkMissingBDSetting('mode')"
                [(ngModel)]="saveBDSetting['mode'].value"
                id="menuDropDown"></p-dropdown>
    <span class="form-field-row-item">
        <small *ngIf="saveBDSetting['mode'].isMissing"
               class="field-error-message">{{saveBDSetting['mode'].errorMessage}}</small>
     </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{saveBDSetting['rangeReserve'].fieldName}}</label>
    <p-inputNumber type="text" class ="form-field-row-item"
                   inputStyleClass="control-rounded {{saveBDSetting['rangeReserve'].isMissing ? 'field-error' : ''}}"
                   [minFractionDigits]="0" [maxFractionDigits]="3"
                   [showButtons] = true
                   [step]="1"
                   [(ngModel)]="saveBDSetting['rangeReserve'].value"
                   (focusout)="checkMissingBDSetting('rangeReserve')"></p-inputNumber>
    <span class="form-field-row-item">
        <small *ngIf="saveBDSetting['rangeReserve'].isMissing"
               class="field-error-message">{{saveBDSetting['rangeReserve'].errorMessage}}</small>
     </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{saveBDSetting['vin'].fieldName}}</label>
    <input type="text" pInputText [(ngModel)]="saveBDSetting['vin'].value" (focusout)="checkMissingBDSetting('vin')"
           class="control-rounded form-field-row-item" [ngClass]="{'field-error' : saveBDSetting['vin'].isMissing}" />
    <span class="form-field-row-item">
        <small *ngIf="saveBDSetting['vin'].isMissing"
               class="field-error-message">{{saveBDSetting['vin'].errorMessage}}</small>
     </span>
  </span>
  <span class="command-dialog-button-wrapper">
      <button class="command-dialog-finish-button" pButton label="Save BD Setting" class="control-rounded" (click)="handleSaveBDSetting()"></button>
  </span>
</p-dialog>

<p-dialog [(visible)]="scActivateDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Smart Charging Onboarding" (onHide)="closeSCActivateDialog()">
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{scActivate['address1'].fieldName}}</label>
    <input type="text" pInputText [(ngModel)]="scActivate['address1'].value" (focusout)="checkMissingSCActivate('address1')"
           class="control-rounded form-field-row-item" [ngClass]="{'field-error' : scActivate['address1'].isMissing}" />
    <span class="form-field-row-item">
        <small *ngIf="scActivate['address1'].isMissing"
               class="field-error-message">{{scActivate['address1'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{scActivate['address2'].fieldName}}</label>
    <input type="text" pInputText [(ngModel)]="scActivate['address2'].value" (focusout)="checkMissingSCActivate('address2')"
           class="control-rounded form-field-row-item" [ngClass]="{'field-error' : scActivate['address2'].isMissing}" />
    <span class="form-field-row-item">
        <small *ngIf="scActivate['address2'].isMissing"
               class="field-error-message">{{scActivate['address2'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{scActivate['city'].fieldName}}</label>
    <input type="text" pInputText [(ngModel)]="scActivate['city'].value" (focusout)="checkMissingSCActivate('city')"
           class="control-rounded form-field-row-item" [ngClass]="{'field-error' : scActivate['city'].isMissing}" />
    <span class="form-field-row-item">
        <small *ngIf="scActivate['city'].isMissing"
               class="field-error-message">{{scActivate['city'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{scActivate['state'].fieldName}}</label>
    <input type="text" pInputText [(ngModel)]="scActivate['state'].value" (focusout)="checkMissingSCActivate('state')"
           class="control-rounded form-field-row-item" [ngClass]="{'field-error' : scActivate['state'].isMissing}" />
    <span class="form-field-row-item">
        <small *ngIf="scActivate['state'].isMissing"
               class="field-error-message">{{scActivate['state'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{scActivate['zipcode'].fieldName}}</label>
    <input type="text" pInputText [(ngModel)]="scActivate['zipcode'].value" (focusout)="checkMissingSCActivate('zipcode')"
           class="control-rounded form-field-row-item" [ngClass]="{'field-error' : scActivate['zipcode'].isMissing}" />
    <span class="form-field-row-item">
        <small *ngIf="scActivate['zipcode'].isMissing"
               class="field-error-message">{{scActivate['zipcode'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{scActivate['country'].fieldName}}</label>
    <input type="text" pInputText [(ngModel)]="scActivate['country'].value" (focusout)="checkMissingSCActivate('country')"
           class="control-rounded form-field-row-item" [ngClass]="{'field-error' : scActivate['country'].isMissing}" />
    <span class="form-field-row-item">
        <small *ngIf="scActivate['country'].isMissing"
               class="field-error-message">{{scActivate['country'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{scActivate['minSOC'].fieldName}}</label>
    <input type="text" pInputText [(ngModel)]="scActivate['minSOC'].value"
           class="control-rounded form-field-row-item"/>
    <span class="form-field-row-item"></span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{scActivate['providerName'].fieldName}}</label>
    <p-dropdown [options]="providerNameOptions" class="form-field-row-item"
                    styleClass="control-rounded"
                    [(ngModel)]="scActivate['providerName'].value" placeholder="Select"
        ></p-dropdown>
    <span class="form-field-row-item">
      <button pButton label="Search" class="control-rounded" (click)="searchProviderName()"></button>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{scActivate['planName'].fieldName}}</label>
    <p-dropdown [options]="planNameOptions" class="form-field-row-item"
                styleClass="control-rounded"
                [(ngModel)]="scActivate['planName'].value" placeholder="Select"
                ></p-dropdown>
    <span class="form-field-row-item">
      <button pButton label="Search" class="control-rounded" (click)="searchPlanName()"></button>
    </span>
  </span>
  <span class="command-dialog-button-wrapper">
      <button class="command-dialog-finish-button" pButton label="Activate" class="control-rounded" (click)="handleSCActivate()"></button>
  </span>
  <div class ="dialog-spinner" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-dialog>

<p-dialog [(visible)]="isVideoStreamVisible" [draggable]="false" [resizable]="false" [modal]="true" [style]="{'min-height':'250px'}" header="Video Streaming" (onHide)="closeVideoStreamDialog()">
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item space-right">{{videoStreamView.fieldName}}</label>
    <p-dropdown [options]="videoStreamViewOptions" class="form-field-row-item space-right"
                styleClass="control-rounded {{videoStreamView.isMissing ? 'field-error': ''}}"
                (onHide)="checkMissingView()"
                (onChange)="checkMissingView()"
                scrollHeight = '100px'
                [(ngModel)]="videoStreamView.value" placeholder="Select"
    ></p-dropdown>
    <span class="form-field-row-item">
      <button pButton label="Start Live Stream" class="control-rounded video-stream-btn" (click)="startLiveStream()"></button>
    </span>
    <span class="form-field-row-item">
      <button pButton label="Get Stream URL" class="control-rounded video-stream-btn" (click)="getStreamURL()"></button>
    </span>
    <span class="form-field-row-item">
      <button pButton label="Stop Live Stream" class="control-rounded video-stream-btn" (click)="stopLiveStream()"></button>
    </span>
  </span>
  <span class="align-content-center" *ngIf="videoStreamLink !== null">
    <iframe width="560" height="315" [src]="videoStreamLink" allowfullscreen title="Video Stream Link"></iframe>
  </span>
  <div class ="dialog-spinner" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-dialog>

<p-dialog [(visible)]="saveBatteryThresholdDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Save Battery Threshold" (onHide)="closeSaveBatteryThreshold()">
  <span class="form-field-row space-bottom">
     <label class="label-text-right form-field-row-item">{{saveBatteryThreshold.fieldName}}</label>
     <p-inputNumber type="text" inputStyleClass="control-rounded control-border form-field-row-item {{saveBatteryThreshold.isMissing ? 'field-error' : ''}}"
                    [(ngModel)]="saveBatteryThreshold.value"
                    (focusout)="checkMissingSaveBatteryThreshold()" [min]="0" [max]="100" suffix="%" placeholder="%"></p-inputNumber>
     <span class="form-field-row-item">
        <small *ngIf="saveBatteryThreshold.isMissing"
               class="field-error-message">{{saveBatteryThreshold.errorMessage}}</small>
     </span>
  </span>
  <span class="command-dialog-button-wrapper">
      <button class="command-dialog-finish-button" pButton label="Save" class="control-rounded" (click)="handleSaveBatteryThreshold()"></button>
    </span>
</p-dialog>

<p-confirmDialog header="Inactivate Battery Threshold Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog [(visible)]="toggleDepartureTimesDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Toggle On/Off Departure Times" (onHide)="closeToggleDepartureTimes()">
  <div class="form-field-row-item"></div>
  <div class="radio-button-wrapper">
    <p-radioButton name="toggle-departure-times-settings" value="On" label="Toggle On"
                   [(ngModel)]="toggleDepartureTimesSettings"
                   class="command-settings-radio-button "></p-radioButton>
    <p-radioButton name="toggle-departure-times-settings" value="Off" label="Toggle Off"
                   [(ngModel)]="toggleDepartureTimesSettings"
                   class="command-settings-radio-button "></p-radioButton>
  </div>
  <div class="form-field-row-item"></div>
  <span class="command-dialog-button-wrapper">
    <button class="command-dialog-finish-button" pButton label="Finish" class="control-rounded" (click)="handleToggleDepartureTimes()"></button>
  </span>
</p-dialog>

<p-dialog [(visible)]="departureTimesDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Departure Times"
          [style]="{width: 'unset'}">
  <p>After updating your departure times, please "Save" at the bottom of this popup.</p>
  <mat-divider class="departure-times-divider"></mat-divider>
  <p-accordion [multiple]="true">
    <div *ngFor="let dayOfTheWeek of departureTimesDayOfTheWeek; let i = index" class="departure-times-accordion-tab">
      <p-accordionTab header="{{dayOfTheWeek}}">
        <p>Times:</p>
        <div *ngFor="let numTimes of departureTimesNumPerDay" class="departure-times-day-form">
          <div class="departure-times-controls-container">
            <p-dropdown class="departure-times-time-dropdown" [options]="departureTimesHrOptions" scrollHeight="5em" [autoDisplayFirst]="false" [(ngModel)]="departureTimesCalendarDaysList[i].scheduleFromCloudList[numTimes].hr"></p-dropdown>
            :
            <p-dropdown class="departure-times-time-dropdown" [options]="departureTimesMinOptions" scrollHeight="5em" [autoDisplayFirst]="false" [(ngModel)]="departureTimesCalendarDaysList[i].scheduleFromCloudList[numTimes].min"></p-dropdown>
            <p>Temperature:</p>
            <p-dropdown class="departure-times-temp-dropdown" [options]="departureTimesTempOptions" [autoDisplayFirst]="false" [(ngModel)]="departureTimesCalendarDaysList[i].scheduleFromCloudList[numTimes].preConditioningTemp"></p-dropdown>
          </div>
          <div class="departure-times-clear-button-container">
          <span class="command-dialog-button-wrapper">
            <button *ngIf="departureTimesCalendarDaysList[i].scheduleFromCloudList[numTimes].hr != null ||
                    departureTimesCalendarDaysList[i].scheduleFromCloudList[numTimes].min != null"
                    pButton label="Clear" class="control-rounded departure-times-clear-button" (click)="handleClearDepartureTime(i, numTimes)"></button>
          </span>
          </div>
        </div>
      </p-accordionTab>
    </div>
  </p-accordion>

  <div class="form-field-row-item">Is enabled: {{departureTimesSaveDepartureTimesRequest.isEnabled}}</div>
  <span class="departure-times-dialog-button-wrapper">
      <button id="departure-times-save-button" class="command-dialog-finish-button" pButton label="Save" class="control-rounded" (click)="handleSaveDepartureTimes()"></button>
      <button class="command-dialog-finish-button" pButton label="Delete All Values" class="control-rounded" (click)="handleDeleteAllDepartureTimes()"></button>
  </span>
</p-dialog>

<p-dialog [(visible)]="globalChargeDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Global Start/Stop Charge">
  <div class="last-command-wrapper">
    <span><span class="last-command-label">Last Command ID: </span>{{globalChargeLastCorrelationId ?? "No previous command found."}}</span>
    <span class="command-dialog-button-wrapper">
      <button class="control-rounded" pButton label="Status of Last Command" (click)="handleGlobalCharge('Last Command Status')"></button>
    </span>
  </div>
  <div class="multiple-command-button-wrapper">
    <div class="form-field-row-item">
      <span class="command-dialog-button-wrapper">
        <button class="command-dialog-finish-button control-rounded" pButton label="Start" (click)="handleGlobalCharge('Start')"></button>
      </span>
    </div>
    <div class="form-field-row-item">
      <span class="command-dialog-button-wrapper">
        <button class="command-dialog-finish-button control-rounded" pButton label="Stop" (click)="handleGlobalCharge('Stop')"></button>
      </span>
    </div>
    <div class="form-field-row-item">
      <span class="command-dialog-button-wrapper">
        <button class="command-dialog-finish-button control-rounded" pButton label="Cancel" (click)="handleGlobalCharge('Cancel')"></button>
      </span>
    </div>
  </div>
  <span class="command-dialog-button-wrapper">
    <button class="control-rounded" pButton label="Close" (click)="closeGlobalCharge()"></button>
  </span>
</p-dialog>

<p-dialog [(visible)]="unsavedChargeStationDialogVisible" [draggable]="false" [resizable]="false" [modal]="true"
          header="Unsaved Charge Locations"
          [style]="{width: 'unset'}"
          (onHide)="closeVPOIDialog()">
  <div class="vpoi-dialog-wrapper" *ngFor="let chargeStation of chargeStationList; let chglocIndex = index">
    <div class="vpoi-dialog-window-left">
      <ng-container [ngTemplateOutlet]="chargeStationLocationNamePhoneAddress" [ngTemplateOutletContext]="{chargeStation: chargeStation}"></ng-container>
      <span class="vpoi-dialog-row justify-content-center">
          <button type="button" pButton class="control-rounded space-right"
                  (click)="saveChargeStation(chglocIndex, chargeStation, true)" label="Save Charge Loc"></button>
          <button type="button" pButton class="control-rounded space-right"
                  (click)="deleteChargeStation(chglocIndex, chargeStation)" label="Delete"></button>
      </span>
    </div>
    <div>
      <div class="vpoi-dialog-row" *ngFor="let sched of defaultChargeProfile[chglocIndex].chargeSchedules;">
        <ng-container [ngTemplateOutlet]="chargeStationSchedule" [ngTemplateOutletContext]="{sched: sched}"></ng-container>
      </div>
      <div class="vpoi-dialog-row">
        <p-radioButton name="chargeNow-true" value="true" label="Charge when plugged in"
                       [(ngModel)]="chargeStationList[chglocIndex].chargeProfile.chargeNow"
                       class="vpoi-dialog-row-item"></p-radioButton>
        <p-radioButton name="chargeNow-true" value="false" label="Wait for preferred charge times"
                       [(ngModel)]="chargeStationList[chglocIndex].chargeProfile.chargeNow"
                       class="vpoi-dialog-row-item"></p-radioButton>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="savedChargeStationDialogVisible" [draggable]="false" [resizable]="false" [modal]="true"
          header="Saved Charge Locations"
          [style]="{width: 'unset'}"
          (onHide)="closeVPOIDialog()">
  <div class="vpoi-dialog-wrapper" *ngFor="let chargeStation of chargeStationList; let chglocIndex = index">
    <div class="vpoi-dialog-window-left">
      <ng-container [ngTemplateOutlet]="chargeStationLocationNamePhoneAddress" [ngTemplateOutletContext]="{chargeStation: chargeStation}"></ng-container>
      <span class="vpoi-dialog-row justify-content-center">
        <span *ngIf="chargeStation.chargeProfile.chargeSchedules == null">
          <button type="button" pButton class="control-rounded space-right"
                  (click)="saveChargeStation(chglocIndex, chargeStation, true)" label="Update Charge Loc"></button>
        </span>
        <span *ngIf="chargeStation.chargeProfile.chargeSchedules !== null">
          <button type="button" pButton class="control-rounded space-right"
                  (click)="saveChargeStation(chglocIndex, chargeStation, false)" label="Update Charge Loc"></button>
        </span>
        <button type="button" pButton class="control-rounded space-right"
                (click)="deleteChargeStation(chglocIndex, chargeStation)" label="Delete"></button>
      </span>
    </div>
    <ng-container [ngTemplateOutlet]="chargeStationScheduleDefaultOrExistingChargeProfile" [ngTemplateOutletContext]="{chargeStation: chargeStation, chglocIndex: chglocIndex}"></ng-container>
  </div>
</p-dialog>

<p-dialog [(visible)]="chargeStationDialogVisible" [draggable]="false" [resizable]="false" [modal]="true"
          header="VPOI Charge Locations"
          [style]="{width: 'unset'}"
          (onHide)="closeVPOIDialog()">
  <div class="vpoi-dialog-wrapper" *ngFor="let chargeStation of chargeStationList; let chglocIndex = index">
    <div class="vpoi-dialog-window-left">
      <ng-container [ngTemplateOutlet]="chargeStationLocationNamePhoneAddress" [ngTemplateOutletContext]="{chargeStation: chargeStation}"></ng-container>
      <span class="vpoi-dialog-row justify-content-center" *ngIf="chargeStation.locationId !== '0'">
        <span *ngIf="chargeStation.chargeProfile.chargeSchedules == null">
          <button type="button" pButton class="control-rounded space-right"
                  (click)="saveChargeStation(chglocIndex, chargeStation, true)" label="Save/Update Charge Loc"></button>
        </span>
        <span *ngIf="chargeStation.chargeProfile.chargeSchedules !== null">
          <button type="button" pButton class="control-rounded space-right"
                  (click)="saveChargeStation(chglocIndex, chargeStation, false)" label="Update Charge Loc"></button>
        </span>
        <button type="button" pButton class="control-rounded space-right"
                (click)="deleteChargeStation(chglocIndex, chargeStation)" label="Delete"></button>
      </span>
      <span class="vpoi-dialog-row justify-content-center" *ngIf="chargeStation.locationId !== '0'">
        <button type="button" pButton class="control-rounded space-right" (click)="getChargeLocByVinAndLocID(chargeStation)" label="Charge Loc Info for VIN and LocID"></button>
      </span>
    </div>
    <ng-container [ngTemplateOutlet]="chargeStationScheduleDefaultOrExistingChargeProfile" [ngTemplateOutletContext]="{chargeStation: chargeStation, chglocIndex: chglocIndex}"></ng-container>
  </div>
</p-dialog>

<ng-template #chargeStationLocationNamePhoneAddress let-chargeStation='chargeStation'>
  <span class="vpoi-dialog-row">
    <label class="label-text-left vpoi-dialog-row-item">Location Name : </label>
    <input type="text" pInputText [(ngModel)]="chargeStation.locationName" class="control-rounded form-field-row-item space-right"/>
  </span>
  <span class="vpoi-dialog-row">
    <label class="label-text-left vpoi-dialog-row-item">Phone number :</label>
    <label class="label-text-left vpoi-dialog-row-item">{{ chargeStation.address.phoneNo }}</label>
  </span>
  <span class="vpoi-dialog-row">
    <label class="label-text-left vpoi-dialog-row-item">Address :</label>
    <span class="vpoi-dialog-row-item">
      <label class="vpoi-dialog-row label-text-left">{{chargeStation.address.address1}},</label>
      <label class="vpoi-dialog-row label-text-left">{{chargeStation.address.address2}}</label>
      <span class="vpoi-dialog-row">
        <label class="label-text-left space-right">{{chargeStation.address.city}}</label>
        <label class="label-text-left space-right">{{chargeStation.address.state}}</label>
      </span>
      <span class="vpoi-dialog-row">
        <label class="label-text-left space-right">{{chargeStation.address.country}}</label>
        <label class="label-text-left space-right">{{chargeStation.address.postalCode}}</label>
      </span>
    </span>
  </span>
</ng-template>

<ng-template #chargeStationSchedule let-sched='sched'>
  <span class="vpoi-dialog-window-right">
    <span class="vpoi-dialog-row">
      <label class="label-text-left vpoi-dialog-row-item">Days : {{sched.days}}</label>
    </span>
    <div class="vpoi-dialog-row" *ngFor="let chargeWindow of sched.chargeWindows; let i = index">
      <label class="label-text-left vpoi-dialog-row-item">Start time : </label>
      <p-dropdown class="vpoi-dialog-row-item" styleClass="control-rounded" [options]="chargeTimings" [autoDisplayFirst]="false"
                  placeholder="00:00" [(ngModel)]="chargeWindow.startTime"></p-dropdown>
      <label class="label-text-left vpoi-dialog-row-item"> End time : </label>
      <p-dropdown class="vpoi-dialog-row-item" styleClass="control-rounded" [options]="chargeTimings" [autoDisplayFirst]="false"
                  placeholder="00:00" [(ngModel)]="chargeWindow.endTime"></p-dropdown>
    </div>
    <div class="vpoi-dialog-row">
      <label class="label-text-left vpoi-dialog-row-item">Desired charge level (%) :</label>
      <p-dropdown class="vpoi-dialog-row-item" styleClass="control-rounded" [options]="chargeLevels" [autoDisplayFirst]="true"
                  [(ngModel)]="sched.desiredChargeLevel"></p-dropdown>
    </div>
  </span>
</ng-template>

<ng-template #chargeStationScheduleDefaultOrExistingChargeProfile let-chargeStation='chargeStation' let-chglocIndex='chglocIndex'>
  <div *ngIf="chargeStation.chargeProfile.chargeSchedules == null">
    <div class="vpoi-dialog-row" *ngFor="let sched of defaultChargeProfile[chglocIndex].chargeSchedules;">
      <ng-container [ngTemplateOutlet]="chargeStationSchedule" [ngTemplateOutletContext]="{sched: sched}"></ng-container>
    </div>
    <div class="vpoi-dialog-row">
      <p-radioButton name="chargeNow-true" value="true" label="Charge when plugged in"
                     [(ngModel)]="chargeStationList[chglocIndex].chargeProfile.chargeNow"
                     class="vpoi-dialog-row-item"></p-radioButton>
      <p-radioButton name="chargeNow-true" value="false" label="Wait for preferred charge times"
                     [(ngModel)]="chargeStationList[chglocIndex].chargeProfile.chargeNow"
                     class="vpoi-dialog-row-item"></p-radioButton>
    </div>
  </div>
  <div *ngIf="chargeStation.chargeProfile.chargeSchedules !== null">
    <div class="vpoi-dialog-row" *ngFor="let sched of chargeStation.chargeProfile.chargeSchedules;">
      <ng-container [ngTemplateOutlet]="chargeStationSchedule" [ngTemplateOutletContext]="{sched: sched}"></ng-container>
    </div>
    <div class="vpoi-dialog-row">
      <p-radioButton name="chargeNow-true" value="true" label="Charge when plugged in"
                     [(ngModel)]="chargeStationList[chglocIndex].chargeProfile.chargeNow"
                     class="vpoi-dialog-row-item"></p-radioButton>
      <p-radioButton name="chargeNow-true" value="false" label="Wait for preferred charge times"
                     [(ngModel)]="chargeStationList[chglocIndex].chargeProfile.chargeNow"
                     class="vpoi-dialog-row-item"></p-radioButton>
    </div>
  </div>
</ng-template>

<p-dialog [(visible)]="geofenceGetByFenceIdDialogVisible" [draggable]="false" [resizable]="false" [modal]="true"
          header="Get Geofence by Fence ID" (onHide)="closeGeofenceGetByFenceId()">
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{geofenceFenceId.fieldName}}</label>
    <input type="text" pInputText
           class="control-rounded control-border form-field-row-item {{geofenceFenceId.isMissing ? 'field-error' : ''}}"
           [(ngModel)]="geofenceFenceId.value" (focusout)="checkMissingGeofenceFenceId()"/>
    <span class="form-field-row-item">
        <small *ngIf="geofenceFenceId.isMissing" class="field-error-message">{{geofenceFenceId.errorMessage}}</small>
    </span>
  </span>
  <span class="command-dialog-button-wrapper">
      <button class="control-rounded" pButton label="Submit" (click)="handleSubmitGeofenceGetByFenceId()"></button>
    </span>
</p-dialog>

<p-dialog [(visible)]="geofenceCreateDialogVisible" [draggable]="false" [resizable]="false" [modal]="true"
          header="Create Geofence" (onHide)="closeGeofenceCreateDialog()">
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{geofenceCreateRequest['fenceName'].fieldName}}</label>
    <input type="text" pInputText
           class="control-rounded control-border form-field-row-item {{geofenceCreateRequest['fenceName'].isMissing ? 'field-error' : ''}}"
           [(ngModel)]="geofenceCreateRequest['fenceName'].value"
           (focusout)="checkMissingGeofenceCreateRequest('fenceName')"/>
    <span class="form-field-row-item">
      <small *ngIf="geofenceCreateRequest['fenceName'].isMissing" class="field-error-message">{{geofenceCreateRequest['fenceName'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{geofenceCreateRequest['fenceTypeCode'].fieldName}}</label>
    <input type="text" pInputText
           class="control-rounded control-border form-field-row-item {{geofenceCreateRequest['fenceTypeCode'].isMissing ? 'field-error' : ''}}"
           [(ngModel)]="geofenceCreateRequest['fenceTypeCode'].value"
           (focusout)="checkMissingGeofenceCreateRequest('fenceTypeCode')"/>
    <span class="form-field-row-item">
      <small *ngIf="geofenceCreateRequest['fenceTypeCode'].isMissing" class="field-error-message">{{geofenceCreateRequest['fenceTypeCode'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{geofenceCreateRequest['fenceValue'].fieldName}}</label>
    <p-inputNumber type="text" styleClass="geofence-create-number-input-wrapper"
                   inputStyleClass="control-rounded control-border geofence-create-number-input {{geofenceCreateRequest['fenceValue'].isMissing ? 'field-error' : ''}}"
                   [(ngModel)]="geofenceCreateRequest['fenceValue'].value"
                   (focusout)="checkMissingGeofenceCreateRequest('fenceValue')"
                   [min]="0" [minFractionDigits]="0" [maxFractionDigits]="3" placeholder="0.000"
                   [showButtons]="true"></p-inputNumber>
    <span class="form-field-row-item">
      <small *ngIf="geofenceCreateRequest['fenceValue'].isMissing" class="field-error-message">{{geofenceCreateRequest['fenceValue'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{geofenceCreateRequest['unitOfMeasurement'].fieldName}}</label>
    <input type="text" pInputText
           class="control-rounded control-border form-field-row-item {{geofenceCreateRequest['unitOfMeasurement'].isMissing ? 'field-error' : ''}}"
           [(ngModel)]="geofenceCreateRequest['unitOfMeasurement'].value"
           (focusout)="checkMissingGeofenceCreateRequest('unitOfMeasurement')"/>
    <span class="form-field-row-item">
      <small *ngIf="geofenceCreateRequest['unitOfMeasurement'].isMissing" class="field-error-message">{{geofenceCreateRequest['unitOfMeasurement'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{geofenceCreateRequest['featurePackageCode'].fieldName}}</label>
    <input type="text" pInputText
            class="control-rounded control-border form-field-row-item {{geofenceCreateRequest['featurePackageCode'].isMissing ? 'field-error' : ''}}"
            [(ngModel)]="geofenceCreateRequest['featurePackageCode'].value"
            (focusout)="checkMissingGeofenceCreateRequest('featurePackageCode')"/>
    <span class="form-field-row-item">
      <small *ngIf="geofenceCreateRequest['featurePackageCode'].isMissing" class="field-error-message">{{geofenceCreateRequest['featurePackageCode'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{geofenceCreateRequest['latitude'].fieldName}}</label>
    <p-inputNumber type="text" styleClass="geofence-create-number-input-wrapper"
                   inputStyleClass="control-rounded control-border geofence-create-number-input {{geofenceCreateRequest['latitude'].isMissing ? 'field-error' : ''}}"
                   [(ngModel)]="geofenceCreateRequest['latitude'].value"
                   (focusout)="checkMissingGeofenceCreateRequest('latitude')"
                   [minFractionDigits]="0" [maxFractionDigits]="6" placeholder="0.000000"
                   [showButtons]="true"></p-inputNumber>
    <span class="form-field-row-item">
      <small *ngIf="geofenceCreateRequest['latitude'].isMissing" class="field-error-message">{{geofenceCreateRequest['latitude'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{geofenceCreateRequest['longitude'].fieldName}}</label>
    <p-inputNumber type="text" styleClass="geofence-create-number-input-wrapper"
                   inputStyleClass="control-rounded control-border geofence-create-number-input {{geofenceCreateRequest['longitude'].isMissing ? 'field-error' : ''}}"
                   [(ngModel)]="geofenceCreateRequest['longitude'].value"
                   (focusout)="checkMissingGeofenceCreateRequest('longitude')"
                   [minFractionDigits]="0" [maxFractionDigits]="6" placeholder="0.000000"
                   [showButtons]="true"></p-inputNumber>
    <span class="form-field-row-item">
      <small *ngIf="geofenceCreateRequest['longitude'].isMissing" class="field-error-message">{{geofenceCreateRequest['longitude'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{geofenceCreateRequest['altitude'].fieldName}}</label>
    <p-inputNumber type="text" styleClass="geofence-create-number-input-wrapper"
                   inputStyleClass="control-rounded control-border geofence-create-number-input {{geofenceCreateRequest['altitude'].isMissing ? 'field-error' : ''}}"
                   [(ngModel)]="geofenceCreateRequest['altitude'].value"
                   (focusout)="checkMissingGeofenceCreateRequest('altitude')"
                   [minFractionDigits]="0" [maxFractionDigits]="3" placeholder="0.000"
                   [showButtons]="true"></p-inputNumber>
    <span class="form-field-row-item">
      <small *ngIf="geofenceCreateRequest['altitude'].isMissing" class="field-error-message">{{geofenceCreateRequest['altitude'].errorMessage}}</small>
    </span>
  </span>
  <span class="command-dialog-button-wrapper">
      <button class="control-rounded" pButton label="Submit" (click)="handleSubmitGeofenceCreate()"></button>
  </span>
</p-dialog>

<p-dialog [(visible)]="stationsByLocationDialogVisible" [draggable]="false" [resizable]="false" [modal]="true"
          header="Enter Location Info" (onHide)="closeStationByLocationDialog()">
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{gsByLocationRequest['latitude'].fieldName}}</label>
    <p-inputNumber type="text" styleClass="geofence-create-number-input-wrapper"
                   inputStyleClass="control-rounded control-border geofence-create-number-input {{gsByLocationRequest['latitude'].isMissing ? 'field-error' : ''}}"
                   [(ngModel)]="gsByLocationRequest['latitude'].value"
                   (focusout)="checkMissingGSByLocationRequest('latitude')"
                   [minFractionDigits]="0" [maxFractionDigits]="6" placeholder="0.000000"
                   [showButtons]="true"></p-inputNumber>
    <span class="form-field-row-item">
      <small *ngIf="gsByLocationRequest['latitude'].isMissing" class="field-error-message">{{gsByLocationRequest['latitude'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{gsByLocationRequest['longitude'].fieldName}}</label>
    <p-inputNumber type="text" styleClass="geofence-create-number-input-wrapper"
                   inputStyleClass="control-rounded control-border geofence-create-number-input {{gsByLocationRequest['longitude'].isMissing ? 'field-error' : ''}}"
                   [(ngModel)]="gsByLocationRequest['longitude'].value"
                   (focusout)="checkMissingGSByLocationRequest('longitude')"
                   [minFractionDigits]="0" [maxFractionDigits]="6" placeholder="0.000000"
                   [showButtons]="true"></p-inputNumber>
    <span class="form-field-row-item">
      <small *ngIf="gsByLocationRequest['longitude'].isMissing" class="field-error-message">{{gsByLocationRequest['longitude'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{gsByLocationRequest['radius'].fieldName}}</label>
     <p-inputNumber type="text" styleClass="geofence-create-number-input-wrapper"
                    inputStyleClass="control-rounded control-border geofence-create-number-input {{gsByLocationRequest['radius'].isMissing ? 'field-error' : ''}}"
                    [(ngModel)]="gsByLocationRequest['radius'].value"
                    (focusout)="checkMissingGSByLocationRequest('radius')"
                    [min]="0" [minFractionDigits]="0" [maxFractionDigits]="3" placeholder="0.000"
                    [showButtons]="true"></p-inputNumber>
    <span class="form-field-row-item">
      <small *ngIf="gsByLocationRequest['radius'].isMissing" class="field-error-message">{{gsByLocationRequest['radius'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{gsByLocationRequest['regionCode'].fieldName}}</label>
    <p-dropdown [options]="regionCodeOptions" class="form-field-row-item"
                   styleClass="control-rounded {{gsByLocationRequest['regionCode'].isMissing ? 'field-error' : ''}}"
                   (onHide)="checkMissingGSByLocationRequest('regionCode')"
                   (onChange)="checkMissingGSByLocationRequest('regionCode')"
                   [(ngModel)]="gsByLocationRequest['regionCode'].value">
    </p-dropdown>
    <span class="form-field-row-item">
      <small *ngIf="gsByLocationRequest['regionCode'].isMissing" class="field-error-message">{{gsByLocationRequest['regionCode'].errorMessage}}</small>
    </span>
  </span>
  <span class="command-dialog-button-wrapper">
      <button class="control-rounded" pButton label="Get GS Nearby" (click)="getGSByLocation()"></button>
  </span>
</p-dialog>

<p-dialog [(visible)]="stationsByLocationIdDialogVisible" [draggable]="false" [resizable]="false" [modal]="true"
          header="Enter Location Id" (onHide)="closeStationByLocationIdDialog()">
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{gsByLocationIdRequest['locationId'].fieldName}}</label>
    <input type="text" pInputText
           class="control-rounded control-border form-field-row-item {{gsByLocationIdRequest['locationId'].isMissing ? 'field-error' : ''}}"
           [(ngModel)]="gsByLocationIdRequest['locationId'].value"
           (focusout)="checkMissingGSByLocationIdRequest('locationId')"/>
    <span class="form-field-row-item">
      <small *ngIf="gsByLocationIdRequest['locationId'].isMissing" class="field-error-message">{{gsByLocationIdRequest['locationId'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{gsByLocationIdRequest['regionCode'].fieldName}}</label>
    <p-dropdown [options]="regionCodeOptions" class="form-field-row-item"
                styleClass="control-rounded {{gsByLocationIdRequest['regionCode'].isMissing ? 'field-error' : ''}}"
                (onHide)="checkMissingGSByLocationIdRequest('regionCode')"
                (onChange)="checkMissingGSByLocationIdRequest('regionCode')"
                [(ngModel)]="gsByLocationIdRequest['regionCode'].value">
    </p-dropdown>
    <span class="form-field-row-item">
      <small *ngIf="gsByLocationIdRequest['regionCode'].isMissing" class="field-error-message">{{gsByLocationIdRequest['regionCode'].errorMessage}}</small>
    </span>
  </span>
  <span class="command-dialog-button-wrapper">
      <button class="control-rounded" pButton label="Search" (click)="getGSByLocationId()"></button>
    </span>
</p-dialog>

<p-dialog [(visible)]="zoneLightingOnOffDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Zone Lighting On/Off"
          (onHide)="closeZoneLightingOnOffDialog()">
  <div class="last-command-wrapper">
    <span><span class="last-command-label">Last Command ID: </span>{{zoneLightingOnOffLastCommandId ?? "No previous command found."}}</span>
    <span class="command-dialog-button-wrapper">
      <button class="control-rounded" pButton label="Status of Last Command" (click)="handleZoneLightingOnOff('Last Command Status')"></button>
    </span>
  </div>
  <div id="zone-lighting-on-off-wrapper" class="multiple-command-button-wrapper">
    <div class="form-field-row-item">
      <span id="zone-select-wrapper" class="command-dialog-button-wrapper">
        <span class="space-right">{{zoneLightingZone.fieldName}}</span>
        <p-dropdown [options]="zoneOptions" class="form-field-row-item" scrollHeight="75px"
                    placeholder="&nbsp;"
                    styleClass="control-rounded {{zoneLightingZone.isMissing ? 'field-error' : ''}}"
                    (onHide)="checkMissingZoneLightingZone()"
                    (onChange)="checkMissingZoneLightingZone()"
                    [(ngModel)]="zoneLightingZone.value"></p-dropdown>
      </span>
    </div>
    <div class="form-field-row-item">
      <span class="command-dialog-button-wrapper">
        <button class="command-dialog-finish-button zone-lighting-on-off-button control-rounded" pButton label="Turn Zone ON" (click)="handleZoneLightingOnOff('ON')"></button>
      </span>
    </div>
    <div class="form-field-row-item">
      <span class="command-dialog-button-wrapper">
        <button class="command-dialog-finish-button zone-lighting-on-off-button control-rounded" pButton label="Turn Zone OFF" (click)="handleZoneLightingOnOff('OFF')"></button>
      </span>
    </div>
  </div>
  <span class="command-dialog-button-wrapper">
    <button class="control-rounded" pButton label="Close" (click)="closeZoneLightingOnOffDialog()"></button>
  </span>
</p-dialog>

<p-dialog [(visible)]="zoneLightingActivateInactivateDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Zone Lighting Activate/Inactivate"
          (onHide)="closeZoneLightingActivateInactivateDialog()">
  <div class="last-command-wrapper">
    <span><span class="last-command-label">Last Command ID: </span>{{zoneLightingActivateInactivateLastCommandId ?? "No previous command found."}}</span>
    <span class="command-dialog-button-wrapper">
      <button class="control-rounded" pButton label="Status of Last Command" (click)="handleZoneLightingActivateInactivate('Last Command Status')"></button>
    </span>
  </div>
  <div class="multiple-command-button-wrapper">
    <div class="zone-lighting-activate-inactivate-form-item">
      <span class="command-dialog-button-wrapper">
        <button class="command-dialog-finish-button control-rounded" pButton label="Activate" (click)="handleZoneLightingActivateInactivate('Activate')"></button>
      </span>
    </div>
    <div class="zone-lighting-activate-inactivate-form-item">
      <span class="command-dialog-button-wrapper">
        <button class="command-dialog-finish-button control-rounded" pButton label="Inactivate" (click)="handleZoneLightingActivateInactivate('Inactivate')"></button>
      </span>
    </div>
  </div>
  <span class="command-dialog-button-wrapper">
    <button class="control-rounded" pButton label="Close" (click)="closeZoneLightingActivateInactivateDialog()"></button>
  </span>
</p-dialog>

<p-dialog [(visible)]="trailerLightCheckDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Trailer Light Check">
  <div class="last-command-wrapper">
    <span><span class="last-command-label">Last Command ID: </span>{{trailerLightCheckLastCommandId ?? "No previous command found."}}</span>
    <span class="command-dialog-button-wrapper">
      <button class="control-rounded" pButton label="Status of Last Command" (click)="handleTrailerLightCheck('Last Command Status')"></button>
    </span>
  </div>
  <div class="multiple-command-button-wrapper">
    <div class="form-field-row-item">
      <span class="command-dialog-button-wrapper">
        <button class="command-dialog-finish-button control-rounded" pButton label="Start" (click)="handleTrailerLightCheck('Start')"></button>
      </span>
    </div>
    <div class="form-field-row-item">
      <span class="command-dialog-button-wrapper">
        <button class="command-dialog-finish-button control-rounded" pButton label="Stop" (click)="handleTrailerLightCheck('Stop')"></button>
      </span>
    </div>
  </div>
  <span class="command-dialog-button-wrapper">
    <button class="control-rounded" pButton label="Close" (click)="closeTrailerLightCheck()"></button>
  </span>
</p-dialog>


<p-dialog [(visible)]="plugAndChargeDialogVisible" [draggable]="false" [resizable]="false" [modal]="true"
          header="Plug & Charge" (onHide)="closePlugAndCharge()">
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{plugAndChargePackageCode.fieldName}}</label>
    <input type="text" pInputText
           class="control-rounded control-border form-field-row-item {{plugAndChargePackageCode.isMissing ? 'field-error' : ''}}"
           [(ngModel)]="plugAndChargePackageCode.value" (focusout)="checkMissingPlugAndChargePackageCode()"/>
    <span class="form-field-row-item">
        <small *ngIf="plugAndChargePackageCode.isMissing" class="field-error-message">{{plugAndChargePackageCode.errorMessage}}</small>
    </span>
  </span>
  <span class="command-dialog-button-wrapper">
      <button class="control-rounded" pButton label="Submit" (click)="handleSubmitPlugAndCharge()"></button>
    </span>
</p-dialog>

<p-dialog [(visible)]="authorizationStatusChangeDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Authorization Status Change"
          (onHide)="closeAuthorizationStatusChangeDialog()">
  <div class="vpoi-dialog-row">
    <p-radioButton [value]= "true" label="Enable CCS (true)"
                   [(ngModel)]="enableTelemetry" class="space-right"></p-radioButton>
    <p-radioButton [value]="false" label="Disable CCS (false)"
                   [(ngModel)]="enableTelemetry" class="space-right"></p-radioButton>
    <button class="control-rounded" pButton label="Submit" (click)="submitAuthorizationStatusChange()"></button>
  </div>
</p-dialog>

<p-dialog [(visible)]="deviceDiagnosticDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Device Diagnostic Properties"
          (onHide)="closeDeviceDiagnosticDialog()">
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{deviceDiagnosticRequest['logType'].fieldName}}</label>
    <input type="text" pInputText
           class="control-rounded control-border form-field-row-item {{deviceDiagnosticRequest['logType'].isMissing ? 'field-error' : ''}}"
           [(ngModel)]="deviceDiagnosticRequest['logType'].value"
           (focusout)="checkMissingDeviceDiagnosticRequest('logType')"/>
    <span class="form-field-row-item">
      <small *ngIf="deviceDiagnosticRequest['logType'].isMissing" class="field-error-message">{{deviceDiagnosticRequest['logType'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{deviceDiagnosticRequest['byteStreamUri'].fieldName}}</label>
    <input type="text" pInputText
           class="control-rounded control-border form-field-row-item {{deviceDiagnosticRequest['byteStreamUri'].isMissing ? 'field-error' : ''}}"
           [(ngModel)]="deviceDiagnosticRequest['byteStreamUri'].value"
           (focusout)="checkMissingDeviceDiagnosticRequest('byteStreamUri')"/>
    <span class="form-field-row-item">
      <small *ngIf="deviceDiagnosticRequest['byteStreamUri'].isMissing" class="field-error-message">{{deviceDiagnosticRequest['byteStreamUri'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row align-content-center">
    <button class="control-rounded" pButton label="Submit" (click)="submitDeviceDiagnosticCommand()"></button>
  </span>
</p-dialog>

<p-dialog [(visible)]="softResetDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Soft Reset"
          (onHide)="closeSoftResetDialog()">
  <span class="form-field-row space-bottom">
    <label class="label-text-right form-field-row-item">{{softResetRequest['moduleName'].fieldName}}</label>
    <input type="text" pInputText
           class="control-rounded control-border form-field-row-item {{softResetRequest['moduleName'].isMissing ? 'field-error' : ''}}"
           [(ngModel)]="softResetRequest['moduleName'].value"
           (focusout)="checkMissingModuleName()"/>
    <span class="form-field-row-item">
      <small *ngIf="softResetRequest['moduleName'].isMissing" class="field-error-message">{{softResetRequest['moduleName'].errorMessage}}</small>
    </span>
  </span>
  <span class="form-field-row  justify-content-center">
     <button class="control-rounded form-field-row-item" pButton label="Submit" (click)="submitSoftResetCommand()"></button>
  </span>
  <span class="form-field-row align-content-center">

  </span>
</p-dialog>

<p-dialog [(visible)]="vehicleCommandStatusDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="Vehicle Command Status" (onHide)="closeVehicleCommandStatus()">
  <span class="form-field-row space-bottom">
     <label class="label-text-right form-field-row-item">{{vehicleCmdId.fieldName}}</label>
     <input type="text" pInputText
           class="control-rounded control-border form-field-row-item {{vehicleCmdId.isMissing ? 'field-error' : ''}}"
           [(ngModel)]="vehicleCmdId.value"
           (focusout)="checkMissingVehicleCommandId()"/>
     <span class="form-field-row-item">
        <small *ngIf="vehicleCmdId.isMissing"
               class="field-error-message">{{vehicleCmdId.errorMessage}}</small>
     </span>
  </span>
  <span class="command-dialog-button-wrapper">
      <button class="command-dialog-finish-button" pButton label="Get Vehicle Command Status" class="control-rounded" (click)="getVehicleCommandStatus()"></button>
  </span>
</p-dialog>

<p-dialog [(visible)]="oemCommandStatusDialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="OEM Command Status" (onHide)="closeOEMCommandStatus()">
  <span class="form-field-row space-bottom">
     <label class="label-text-right form-field-row-item">{{oemCmdId.fieldName}}</label>
     <input type="text" pInputText
            class="control-rounded control-border form-field-row-item {{oemCmdId.isMissing ? 'field-error' : ''}}"
            [(ngModel)]="oemCmdId.value"
            (focusout)="checkMissingOEMCommandId()"/>
     <span class="form-field-row-item">
        <small *ngIf="oemCmdId.isMissing"
               class="field-error-message">{{oemCmdId.errorMessage}}</small>
     </span>
  </span>
  <span class="command-dialog-button-wrapper">
      <button class="command-dialog-finish-button" pButton label="Get OEM Command Status" class="control-rounded" (click)="getOEMCommandStatus()"></button>
  </span>
</p-dialog>


