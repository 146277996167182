import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Params} from '@angular/router';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiService {

	constructor(private http: HttpClient) {}

	get<T> (server: string, path: string, headers?: Params): Observable<T> {
		return  this.http.get<T>(server + path, {headers: headers, withCredentials: true});
	}

	post<T> (server: string, path: string, body: any | null, headers?: Params): Observable<T> {
     return this.http.post<T>(server + path, body, {headers: headers, withCredentials: true});
	}

  delete<T> (server: string, path: string, headers?: Params, body?: any): Observable<T> {
    // Don't include server when using the proxy
    // return  this.http.post<T>(server + path, body);
    return  this.http.delete<T>(server + path, {headers: headers, body: body, withCredentials: true});
  }

  put<T> (server: string, path: string, body: any | null, headers?: Params): Observable<T> {
    // Don't include server when using the proxy
    // return  this.http.post<T>(server + path, body);
    return  this.http.put<T>(server + path, body, {headers: headers, withCredentials: true});
  }
}
