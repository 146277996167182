export interface Options {
  label: string,
  value: string
}

export interface TypeRequest {
  type: Type
}

export enum Type {
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  COOLING = 'COOLING',
  HEATING = 'HEATING',
  REMOTE_START = 'remoteStart',
  CANCEL_REMOTE_START = 'cancelRemoteStart',
  STATUS_UPDATE = 'statusRefresh',
  TELEMETRY_SETTINGS = 'configureTelemetryEmission'
}

export interface CommandRequest {
  type: Type,
  properties : any
}

export interface Setting {
  "setting": SettingType
}

export enum SettingType {
  ON = 'ON',
  OFF = 'OFF',
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  ENABLE_SANS_LOCATION = 'ENABLE_SANS_LOCATION'
}

export interface AuthorizeTMCVinRequest {
  odometerReading: number
}

export interface SmartChargeActivateRequest {
  isOptedIn: boolean,
  minSoc: number,
  address: Address,
  utility: UtilityDetails,
}

export interface SmartChargeMinSOCRequest{
  minSoc: number
}

export interface SmartChargeOptInOptOutRequest{
  isOptedIn: boolean
}

export interface SmartChargeResponse {
  status: string,
  vin: string,
  minSoc: number,
  isActivated: boolean,
  isOnboarded: boolean,
  isOptedIn: boolean,
  utility: UtilityDetails,
  address: Address,
  preconditionLevel: string,
  activities: string
}

export interface SmartChargeUtilityResponse {
  status: string,
  utilities: Utilities[]
}

export interface Address {
  address1: string,
  address2: string,
  city: string,
  state: string,
  postalCode: string,
  country: string,
  latitude: number,
  longitude: number,
  phoneNo?: string
}

export interface UtilityDetails {
  utilityId: string,
  utilityName: string,
  utilityPlanId: string,
  utilityPlanName: string
}

export interface Utilities {
  id: string,
  code: string,
  name: string,
  utilityTariffs: UtilityTariffs[]
}

export interface UtilityTariffs {
  id: string,
  code: string,
  name: string,
  hasTouRates: boolean
}

export interface SaveBatteryThresholdRequest {
  vin: string,
  value: number
}

export enum BidirectionalSettingsMode {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
  OFF = 'OFF'
}

export interface BidirectionalSettingsRequest {
  vin: string,
  mode: BidirectionalSettingsMode,
  rangeReserve: number
}

export interface DepartureTimesScheduleFromCloud {
  hr: number,
  min: number,
  preConditioningTemp: string
}

export interface DepartureTimesCalendarDay {
  calendarDay: number,
  scheduleFromCloudList: DepartureTimesScheduleFromCloud[]
}

export interface DepartureTimesScheduleCloudData {
  calendarDaysList: DepartureTimesCalendarDay[]
}

export interface SaveDepartureTimesRequest {
  vin: string,
  isEnabled: string,
  goTimesScheduleCloudData: DepartureTimesScheduleCloudData
}

export enum GlobalChargeCommandType {
  GLOBAL_START,
  GLOBAL_PAUSE,
  GLOBAL_CANCEL
}

export interface GlobalChargeCommandRequest {
  vin: string,
  commandType: GlobalChargeCommandType
}

export interface  EVCommandStatusRequest {
  vin: string,
  correlationId: number
}

export interface VideoStreamResponse {
  code?: number,
  message?: string,
  result: VideoStreamResults,
  error: VideoStreamError
}

export interface VideoStreamResults {
  id: string,
  vin: string,
  url: string,
  views: View[]
}

export interface View {
  id: number,
  status: string,
  quality: string
}

export interface VideoStreamError {
  errorCode: string,
  messages: string[]
}

export interface VPOIResponse {
  vin: string;
  userId: string;
  dataSource: string;
  locationId: string;
  locationName: string;
  chargeTarget: string;
  locationRadiusToAct: string;
  minsoc: number;
  savedLocationId: number;
  unSavedLocationId: number;
  address: Address;
  chargerType: string;
  energy: string;
  type: string;
  curntTrgtSoc: number;
  chargeDuration: ChargeDuration;
  chargeProfile: ChargeProfile;
  correlationId: string;
  code?: number;
  message?: string;
  responseData?: Map<string, string>;
}

export interface ChargeDuration {
  settings: string;
  duration: number;
}

export interface ChargeProfile {
  chargeNow: boolean;
  utilityProvider: UtilityProvider;
  chargeSchedules: ChargeSchedules[];
}

export interface UtilityProvider {
  id: string;
  providerName: string;
  planId: string;
  planName: string;
  zipCode: string;
  seasonStartDate: string;
  seasonEndDate: string;
  price: number;
  country: string;
}

export interface ChargeSchedules {
  days: string;
  desiredChargeLevel: number;
  chargeWindows: ChargeWindow[];
}

export interface ChargeWindow {
  startTime: string;
  endTime: string;
}

export interface VPOIResponseList {
  correlationId: string;
  vpoiResponses: VPOIResponse[];
  code?: number;
  message?: string;
  responseData?: Map<string, string>;
}

export interface VPOIErrorResponse {
  app: string;
  code: string,
  correlationId: string;
  message: string;
}

export interface GeofenceCreateRequest {
  fence_name: string,
  fencetype_code: string,
  fence_value: number,
  unit_of_measurement: string,
  feature_package_code: string,
  geofence_details: GeofenceDetails[],
  notification_status: boolean
}

export interface GeofenceDetails {
  lat: number,
  lon: number,
  alt: number
}

export interface TrailerLightStatusRequest {
  VehicleStatusQueryData: string[]
}

export interface OEMRequest extends AUCredentials{
  type: string,
  properties: OEMProperties
}

export interface AUCredentials {
  clientId: string,
  clientSecret: string
}

export interface OEMProperties {
  enableTelemetry?: boolean,
  authorizationMode?: string,
  moduleName?: string,
  logType?: string,
  bytestreamUri?: string
}
