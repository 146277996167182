export const environment = {
  production: true,
  api_base_url: 'https://mobileapp-simulator-service-stg-xhpvc47b7a-uc.a.run.app',
  openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
  openidClientId: 'urn:mobapp_sim-clientid-web_mobile_simulator-stg',
  openidResource: 'urn:mobapp_sim-resource-web_mobile_simulator-stg',
  env: ' STG ',
  b2c_redirect_uri: 'https://mappsim.ford.com/',
  otp_redirect_base_url: 'https://mappsim.ford.com/',
  b2c_clientId: '805cf4c1-77ab-4e57-9a91-0ff75ab6f6bd',
  ford_application_id: 'DFD59BC8-4B07-44ED-A91A-4EE77E71E6AD',
  b2c_scope: '805cf4c1-77ab-4e57-9a91-0ff75ab6f6bd openid',
  b2c_loginUrl: 'https://loginqa.ford.com/e75ff8bf-6627-43e3-b410-9fc74d252d29/B2C_1A_QA_SignInSignUp_en-US/oauth2/v2.0/authorize?ui_locales=en-US&country_code=USA&language_code=en-US&response_type=code'

};
