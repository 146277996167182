import {MessageService} from "primeng/api";
import {FormField} from "./shared-models.model";
import {Router} from "@angular/router";

export function showMissingFieldsToast(messageService: MessageService, formFields: Record<string, FormField>) {
  let fieldsList: string[] = [];
  Object.values(formFields).filter(field => field.isMissing)
    .forEach(field => {
      if (field.fieldName != null) {
        fieldsList.push(field.fieldName);
      }
    });

  messageService.add({severity: 'error', summary: 'Missing Fields', detail: fieldsList.join('\n')});
}

export function showInvalidFieldsToast(messageService: MessageService, formFields: Record<string, FormField>) {
  let fieldsList: string[] = [];
  Object.values(formFields).filter(field => field.isInvalid)
    .forEach(field => {
      if (field.fieldName != null) {
        fieldsList.push(field.fieldName);
      }
    });

  messageService.add({severity: 'error', summary: 'Invalid Fields', detail: fieldsList.join('\n')});
}

export function isVinInvalid(vin: string): boolean {
  return !/^((?<wmi>[A-HJ-NPR-Z\d]{3})(?<vds>[A-HJ-NPR-Z\d]{6})(?<vis>[A-HJ-NPR-Z\d]{8}))?$/.test(vin);
}

export function handleError(messageService: MessageService, error: any, router: Router){
  if (Math.floor(error.status / 100) == 5) {
    messageService.add({severity: 'error', summary: 'Server Error: ' +  error.status + ' ' + error.statusText, detail: "An unexpected server error occurred.", life: 5000});
  } else if(error.status == 400 || error.status == 401) {
    // 400 Bad Request: cookie is expired, redirect to login page
    messageService.add({severity: 'error', summary: 'Session Expired', detail: 'Please login again', life: 5000});
    sessionStorage.removeItem('loginUser');
    sessionStorage.removeItem('selectedVin');
    sessionStorage.removeItem('commandResults');
    sessionStorage.removeItem('isAULogin');
    router.navigate(['/login']);
  } else {
    messageService.add({severity: 'error', summary: 'Error: ' +  error.status + ' ' + error.statusText,
      detail: 'An unexpected error occurred.'});
  }
}

export function leadingZeros(num: number): string {
  if (num >= 0 && num < 10) {
    return "0" + num.toString();
  }
  else {
    return num.toString();
  }
}
