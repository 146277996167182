import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  vinEvent: EventEmitter<string> = new EventEmitter<string>();
  nameEvent: EventEmitter<string> = new EventEmitter<string>();
  loginEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
}
