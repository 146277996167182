import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class OauthGuardService implements CanActivate {

	constructor(private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		// Store state url in sessionStorage for later redirect by callback component

		sessionStorage['redirectURL'] = state.url;
		return this.checkLogin();
	}

	/** Function used to determine if the current token is valid. Checks token expiration against the current timestamp
	 * @returns {boolean} whether or not the token is expired
	 */
	isTokenExpired(): boolean {
		const epoch = Math.trunc(new Date().getTime() / 1000);
		let expEpoch = null;

		if (sessionStorage.getItem('tokenExp') && sessionStorage.getItem('tokenExp') !== 'null') {
			expEpoch = parseInt(sessionStorage.getItem('tokenExp'), 10);
			return (epoch >= expEpoch);
		} else {
			return true;
		}
	}

	/** Uses isTokenExpired() to determine if the user credentials should be cleared and the user forwarded to the login component
	 * @returns {boolean} for whether the user is "logged in" or not
	 */
	checkLogin(): boolean {
		// console.log('Auth guard working ----');
		// if (!environment.production) {
		// 	return true;
		// }
		// Check if token is expired (or null)
		if (this.isTokenExpired()) {
			// Clear oauth session items
			sessionStorage.setItem('tokenExp', null);
			sessionStorage.setItem('tokenIssue', null);
			sessionStorage.setItem('strAccessToken', null);
			sessionStorage.setItem('encodedAccessToken', null);

			// Navigate to the login page with extras
			this.router.navigate(['/token-redirect']);

			return false; // guard complete and then router redirects to /token-redirect
		}
		return true;
	}

}
