import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../api/api.service";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {Vin, VinResponse, VinsList} from "./vins.model";

@Injectable({
  providedIn: 'root'
})
export class VinsService {

  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  getVins(): Observable<VinsList> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/getVins', null,{"Application-Id": sessionStorage.getItem('appId')});
  }

  addVin(body: Vin): Observable<VinResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/vehicle/add', body,{"Application-Id": sessionStorage.getItem('appId')});
  }

  deleteVin(vin: string): Observable<VinResponse> {
    return this.apiService.delete<any>(environment.api_base_url, '/api/v1/vehicle/'+vin+'/delete',{"Application-Id": sessionStorage.getItem('appId')});
  }

  updateNickName(body: Vin): Observable<VinResponse> {
    return this.apiService.put<any>(environment.api_base_url, '/api/v1/vehicle/'+body.vin+'/update/', body,{"Application-Id": sessionStorage.getItem('appId')});
  }
}
