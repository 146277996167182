<div class="component-frame">
  <div id="manage-vehicles-contents">
    <span *ngFor="let vin of vinsList; let index=index" class="p-button control-rounded vin-list-button">
      <span class="vin-list-label">{{vin.nickName + ' - ' + vin.vin}}</span>
      <img class="vin-list-image" src="{{vin.image}}" alt="VIN image">
      <span id="manage-vehicles-action-button-container">
        <button id="manage-vehicles-edit-button" pButton class="manage-vehicles-action-button control-rounded"
                (click)="showDialog(index, false)" label="Edit"></button>
        <button pButton class="manage-vehicles-action-button control-rounded"
                (click)="handleRemoveVehicle(index)" label="Remove"></button>
      </span>
    </span>
    <button id="manage-vehicles-add-button" pButton class="control-rounded"
            (click)="showDialog(-1, true)" label="Add Vehicle"></button>
  </div>
  <div class="progress-spinner" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>

<p-dialog [(visible)]="dialogVisible" [draggable]="false" [resizable]="false" [modal]="true" header="">
    <span class="form-field-row">
      <label class="label-text-right form-field-row-item manage-vehicles-label">Nickname</label>
      <input type="text" pInputText class="control-rounded control-border form-field-row-item manage-vehicles-input"
             [(ngModel)]="formFields['nickname'].value" [ngClass]="{'field-error' : formFields['nickname'].isMissing}"
             (focusout)="checkInvalidField('nickname')"/>
      <span class="form-field-row-item manage-vehicles-error-message">
        <small *ngIf="formFields['nickname'].isMissing"
               class="field-error-message">{{formFields['nickname'].errorMessage}}</small>
      </span>
    </span>
    <span class="form-field-row">
      <label class="label-text-right form-field-row-item manage-vehicles-label">VIN</label>
      <input [disabled]="!isVinEditable" type="text" pInputText class="control-rounded control-border form-field-row-item manage-vehicles-input"
             [(ngModel)]="formFields['vin'].value" [ngClass]="{'field-error' : formFields['vin'].isMissing || formFields['vin'].isInvalid}"
             (focusout)="checkInvalidField('vin')"/>
      <span class="form-field-row-item manage-vehicles-error-message">
        <small *ngIf="formFields['vin'].isMissing || formFields['vin'].isInvalid"
               class="field-error-message">{{formFields['vin'].errorMessage}}</small>
      </span>
    </span>
    <span id="manage-vehicles-finish-button-wrapper">
      <button id="manage-vehicles-finish-button" pButton label="Finish" class="control-rounded" (click)="handleAddEditVehicle()"></button>
    </span>
</p-dialog>
<p-toast [preventOpenDuplicates]="true"></p-toast>
