<div class="component-frame">
    <div class="main-container">
        <p-table #messageTable id="message-table" [value]="messagesList" dataKey="messageId" [scrollable]="true"
                 [paginator]="true" [(first)]="firstRecord" [totalRecords]="messagesList.length" [rows]="selectedRowsPerPage"
                 [rowsPerPageOptions]="rowsPerPageOptions" [showCurrentPageReport]="false" [showJumpToPageDropdown]="true">
            <ng-template pTemplate="caption">
                <table id="message-table-header">
                  <caption>message table header</caption>
                    <tr>
                        <th id="message-table-header-label-column">Message Center</th>
                        <td id="message-table-lrdt-column">
                            <span class="message-table-control">
                                <span class="message-table-control-label-left">From Date:</span>
                                <p-calendar appendTo="body" [(ngModel)]="lastRetrievedDate" [showButtonBar]="true" [showTime]="true" hourFormat="12"></p-calendar>
                                <button id="message-table-refresh-list-button" type="button" pButton pRipple
                                        class="message-table-header-button p-button-text" [icon]="'pi pi-refresh'" (click)="startCommand('refreshMessages')"></button>
                                <span class="message-table-control-label-right">Refresh List</span>
                            </span>
                        </td>
                        <td id="message-table-mark-as-read-column">
                            <span class="message-table-control">
                                <span class="message-table-control-label-left">Mark as Read When Expanded</span>
                                <p-inputSwitch [(ngModel)]="markAsReadWhenExpanded"
                                       [readonly]="true" pTooltip="Expanding a message without marking it as read is not available at this time."></p-inputSwitch>
                            </span>
                        </td>
                        <td id="message-table-inbox-status-column">
                            <span class="message-table-control">
                                <span class="message-table-control-label-left">Get Inbox Status</span>
                                <button type="button" pButton pRipple class="message-table-header-button p-button-text" [icon]="'pi pi-envelope'" (click)="startCommand('getInboxStatus')"></button>
                            </span>
                        </td>
                    </tr>

                </table>
            </ng-template>
            <ng-template pTemplate="header">
                <tr class="message-row">
                    <th scope="col" class="message-column message-id-col" pSortableColumn="messageId">
                        <span class="message-column-header">
                            Message ID
                            <p-sortIcon field="messageId"></p-sortIcon>
                            <p-columnFilter type="text" field="messageId" display="menu"></p-columnFilter>
                        </span>
                    </th>
                    <th scope="col" class="message-column message-type-col" pSortableColumn="messageType">
                        <span class="message-column-header">
                            Message Type
                            <p-sortIcon field="messageType"></p-sortIcon>
                            <p-columnFilter type="text" field="messageType" display="menu"></p-columnFilter>
                        </span>
                    </th>
                    <th scope="col" class="message-column created-date-col" pSortableColumn="createdDate">
                        <span class="message-column-header">
                            Created Date
                            <p-sortIcon field="createdDate"></p-sortIcon>
                            <p-columnFilter type="date" field="createdDate" display="menu"></p-columnFilter>
                        </span>
                    </th>
                    <th scope="col" class="message-column subject-col" pSortableColumn="messageSubject">
                        <span class="message-column-header">
                            Message Subject
                            <p-sortIcon field="messageSubject"></p-sortIcon>
                            <p-columnFilter type="text" field="messageSubject" display="menu"></p-columnFilter>
                        </span>
                    </th>
                    <th scope="col" class="message-column body-col" pSortableColumn="messageBody">
                        <span class="message-column-header">
                            Body
                            <p-sortIcon field="messageBody"></p-sortIcon>
                            <p-columnFilter type="text" field="messageBody" display="menu"></p-columnFilter>
                        </span>
                    </th>
                    <th id="message-table-show-read-messages-column" class="message-column action-col">
                        <span class="message-table-control">
                            <span class="message-table-control-label-left">Show Read Messages</span>
                            <p-inputSwitch [(ngModel)]="showReadMessages" (onChange)="filterShowHideReadMessages($event.checked)"></p-inputSwitch>
                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-message let-expanded="expanded">
                <tr class="message-row" [ngClass]="{'message-row-read': message.isRead}">
                    <td class="message-column message-id-col">#{{message.messageId}}</td>
                    <td class="message-column message-type-col">{{message.messageType}}</td>
                    <td class="message-column created-date-col">{{formattedDate(message.createdDate)}}</td>
                    <td class="message-column subject-col">{{message.messageSubject}}</td>
                    <td class="message-column body-col">{{message.messageBody}}</td>
                    <td class="message-column action-col">
                        <button class="message-action-button control-rounded" pButton label="Delete" (click)="startCommand('deleteMessage', message)"></button>
                        <button class="message-action-button control-rounded" pButton label="Mark as Read"
                                [disabled]="message.isRead" (click)="startCommand('markMessageAsRead', message)"></button>
                        <button type="button" pButton pRipple [pRowToggler]="message" class="expand-message-button p-button-text p-button-rounded"
                                [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'" (click)="!expanded ? startCommand('getMessageContent', message) : null"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-message>
                <tr class="message-row" [ngClass]="{'message-row-read': message.isRead}" *ngIf="messagesContent.get(message.messageId) != null">
                    <td id="message-table-expanded-body-column" class="message-column">
                        <div class="message-table-content-label">Tag:</div>
                        {{messagesContent.get(message.messageId).tag}}
                        <div class="message-table-content-label">Full Body:</div>
                        {{messagesContent.get(message.messageId).body}}
                        <button id="message-table-content-show-json" (click)="showJSON(message)">View JSON</button>
                    </td>
                    <td id="message-table-expanded-actions-column" class="message-column">
                        <div class="message-table-content-action-row">
                            <span class="message-table-content-label">Relevant VIN:</span>
                            <span class="message-table-content-value">{{messagesContent.get(message.messageId).relevantVin}}</span>
                        </div>
                        <div class="message-table-content-action-row">
                            <span class="message-table-content-label">Action Taken:</span>
                            <span class="message-table-content-value">{{messagesContent.get(message.messageId).actionTaken}}</span>
                        </div>
                        <div class="message-table-content-action-row">
                            <span class="message-table-content-label">Action Taken By:</span>
                            <span class="message-table-content-value">{{messagesContent.get(message.messageId).actionTakenBy}}</span>
                        </div>
                        <div class="message-table-content-action-row">
                            <span class="message-table-content-label">Action Required:</span>
                            <span class="message-table-content-value">{{messagesContent.get(message.messageId).actionRequired}}</span>
                        </div>
                        <div class="message-table-content-action-row">
                            <span class="message-table-content-label">Action Date:</span>
                            <span class="message-table-content-value">{{formattedDateFromString(messagesContent.get(message.messageId).actionDate)}}</span>
                        </div>
                        <div class="message-table-content-action-row message-table-content-allow-deny-buttons-row"
                             *ngIf="messagesContent.get(message.messageId).actionRequired && message.messageType == 'AddAdditionalUser'">
                            <button class="message-table-content-allow-deny-buttons p-button-success control-rounded" pButton
                                    readonly="true" pTooltip="Updating a message to allow an additional user is not available at this time." label="Allow"></button>
                            <button class="message-table-content-allow-deny-buttons p-button-danger control-rounded" pButton
                                    readonly="true" pTooltip="Updating a message to deny an additional user is not available at this time." label="Deny"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr class="message-row">
                    <td>No Messages Found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class ="dialog-spinner" *ngIf="loading">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <p-dialog [(visible)]="showInboxStatusDialog" [draggable]="false" [resizable]="false" [modal]="true" header="Inbox Status">
        <div class="inbox-status-line">
            <span class="inbox-status-label">Total Read Messages:</span>
            <span class="inbox-status-value">{{inboxStatusCountRead}}</span>
        </div>
        <div class="inbox-status-line">
            <span class="inbox-status-label">Total Unread Messages:</span>
            <span class="inbox-status-value">{{inboxStatusCountUnread}}</span>
        </div>
    </p-dialog>

    <p-dialog [(visible)]="showJSONDialog" [draggable]="false" [resizable]="false" [modal]="true" header="Message #{{showJSONMessageId}}">
        <span id="show-json-container">
            <p-panel styleClass="show-json-summary-panel" header="Message Summary">{{showJSONMessageSummary}}</p-panel>
            <p-panel header="Message Content">{{showJSONMessageContent}}</p-panel>
        </span>
    </p-dialog>

</div>
