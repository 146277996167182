
import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {LoginService} from './login.service';
import {B2CLoginResponse, LoginUserResponse} from './login.model';
import {environment} from '../../environments/environment';
import {EventService} from "../shared/event.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  loading: boolean;
  constructor(private loginService: LoginService,
              private router: Router,
              private messageService: MessageService,
              private eventService: EventService) {
  }

  ngOnInit(): void {
    sessionStorage.removeItem('newUserEmail');
    sessionStorage.removeItem('newUserLanguage');
    sessionStorage.removeItem('newUserCountry');
    sessionStorage.removeItem('newUserOTP');

    sessionStorage.setItem('appId',environment.ford_application_id);

    if (sessionStorage.getItem('code') && sessionStorage.getItem('code') !== null) {
      this.loading = true;
      this.loginService.b2cLogin(sessionStorage.getItem('code')).subscribe((response: B2CLoginResponse) => {
        if (response.code === 400) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong'
          });
          sessionStorage.removeItem('code');
        } else {
          sessionStorage.setItem('loginUser',response.userProfile );
          sessionStorage.removeItem('selectedVin');
          sessionStorage.removeItem('code');
          this.notifyUserLoginEvent();
          this.router.navigate(['vins'])
            .then(null, error => {
              const message = 'There was an issue navigating to /vins.';
              this.messageService.add({
                severity: 'error',
                summary: 'Navigation Error',
                detail: message + '\n\n' + error
              });
            });
        }
      }, error => {
        sessionStorage.removeItem('code');
        this.messageService.add({
          severity: 'error', summary: 'Error: ' + error.status + ' ' + error.statusText,
          detail: 'An unexpected error occurred.'
        });
      }).add(() => {
        this.loading = false;
      });
    } else {
      window.location.replace(this.getRedirectUrl());
    }
  }

  getRedirectUrl() : string {
    let _endPoint = environment.b2c_loginUrl +
      '&client_id=' + environment.b2c_clientId +
      '&scope=' + environment.b2c_scope +
      '&ford_application_id=' +environment.ford_application_id +
      '&redirect_uri=' + encodeURIComponent(environment.b2c_redirect_uri);
    return _endPoint;
  }

  notifyUserLoginEvent(){
    this.eventService.loginEvent.emit(true);
  }

}
