import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoginGuardService implements CanActivate {

	constructor(private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		// Store state url in sessionStorage for later redirect by callback component

		sessionStorage['redirectURL'] = state.url;
		return this.checkLogin();
	}

	checkLogin(): boolean {
    //get Code if exists
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    if (code !== undefined && code !== null ) {
      sessionStorage.setItem('code', code);
    }
		// console.log('Auth guard working ----');
		// if (!environment.production) {
		// 	return true;
		// }
		// Check if loginUser has been cleared
		if (sessionStorage.getItem('loginUser') === null) {
			// Navigate to the login page with extras
			this.router.navigate(['/login']);

			return false; // guard complete and then router redirects to /login
		}
		return true;
	}

}
