import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../api/api.service";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {TmcResponse} from "../shared/shared-models.model";
import {DeleteReadMessageRequest} from "./message-center.model";

@Injectable()
export class MessageCenterService {

  constructor(private http: HttpClient, private apiService: ApiService) { }

  getInboxStatus(): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/messagecenter/messages/status',{"Application-Id": sessionStorage.getItem('appId')});
  }

  getMessages(lrdt: Date): Observable<TmcResponse> {
    if (lrdt) {
      return this.apiService.get<any>(environment.api_base_url, '/api/v1/messagecenter/messages/lrdt/' + lrdt.toISOString(),{"Application-Id": sessionStorage.getItem('appId')});
    }
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/messagecenter/messages',{"Application-Id": sessionStorage.getItem('appId')});
  }

  getMessageContent(messageId: string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/messagecenter/messages/' + messageId,{"Application-Id": sessionStorage.getItem('appId')});
  }

  getMessageContentMarkAsRead(messageId: string): Observable<TmcResponse> {
    return this.apiService.put<any>(environment.api_base_url, '/api/v1/messagecenter/messages/' + messageId,"",{"Application-Id": sessionStorage.getItem('appId')});
  }

  markMessagesAsRead(body: DeleteReadMessageRequest): Observable<TmcResponse> {
    return this.apiService.put<any>(environment.api_base_url, '/api/v1/messagecenter/messages/read', body, {"Application-Id": sessionStorage.getItem('appId')});
  }

  deleteMessages(body: DeleteReadMessageRequest): Observable<TmcResponse> {
    return this.apiService.delete<any>(environment.api_base_url, '/api/v1/messagecenter/messages',{"Application-Id": sessionStorage.getItem('appId')}, body);
  }

  // Todo: Update the message on click of the Allow and Deny buttons
  // updateMessage(body: UpdateMessageRequest): Observable<TmcResponse> {
  //   return this.apiService.put<any>(environment.api_base_url, '/api/v1/messagecenter/messages', body,{"Application-Id": sessionStorage.getItem('appId')});
  // }
}
