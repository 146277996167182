import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LoginComponent} from './login/login.component';
import {FormsModule} from "@angular/forms";
import {DropdownModule} from "primeng/dropdown";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {VinsComponent} from './vins/vins.component';
import {PasswordModule} from "primeng/password";
import {CommandsComponent} from './commands/commands.component';
import {ToastModule} from "primeng/toast";
import { ManageVehiclesComponent } from './manage-vehicles/manage-vehicles.component';
import {DialogModule} from "primeng/dialog";
import {RadioButtonModule} from "primeng/radiobutton";
import {CheckboxModule} from "primeng/checkbox";
import {MessageService} from "primeng/api";
import {ApiService} from "./api/api.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HttpInterceptorService} from "./authentication/http-interceptor.service";
import {LoginModule} from "./login/login.module";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {InputMaskModule} from "primeng/inputmask";
import {InputNumberModule} from "primeng/inputnumber";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {AccordionModule} from "primeng/accordion";
import { MessageCenterComponent } from './message-center/message-center.component';
import {TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {InputSwitchModule} from "primeng/inputswitch";
import {CalendarModule} from "primeng/calendar";
import {TooltipModule} from "primeng/tooltip";
import {PanelModule} from "primeng/panel";
import { OauthGuardService } from './authentication/oauth-guard.service';
import {NgxAdfsModule, UserIdService} from '@wame/ngx-adfs';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    VinsComponent,
    CommandsComponent,
    ManageVehiclesComponent,
    MessageCenterComponent,
  ],
    imports: [
        LoginModule,
        HttpClientModule,
        BrowserModule,
        RouterModule,
        AppRoutingModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        BrowserAnimationsModule,
        MatDividerModule,
        FormsModule,
        DropdownModule,
        ButtonModule,
        InputTextModule,
        PasswordModule,
        ToastModule,
        DialogModule,
        RadioButtonModule,
        CheckboxModule,
        ProgressSpinnerModule,
        InputMaskModule,
        InputNumberModule,
        ConfirmDialogModule,
        AccordionModule,
        TableModule,
        RippleModule,
        InputSwitchModule,
        CalendarModule,
        TooltipModule,
        PanelModule,
        NgxAdfsModule.forRoot({
          openidUrl: environment.openidUrl,
          openidClientId: environment.openidClientId,
          openidResource: environment.openidResource
        })
    ],
  providers: [MessageService, ApiService,	UserIdService,
    OauthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }],
  exports: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
