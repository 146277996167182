import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {VinsComponent} from "./vins/vins.component";
import {CommandsComponent} from "./commands/commands.component";
import {ManageVehiclesComponent} from "./manage-vehicles/manage-vehicles.component";
import {OauthGuardService} from "./authentication/oauth-guard.service";
import {LoginGuardService} from "./authentication/login-guard.service";
import {MessageCenterComponent} from "./message-center/message-center.component";
import {AuthenticationGuard, OauthCallbackComponent, OauthTokenGuard} from '@wame/ngx-adfs';
import {NotFoundComponent} from '@wame/ngx-frf-utilities';

const routes: Routes = [
  {
    path: 'oauth',
    component: OauthCallbackComponent,
    canActivate: [OauthTokenGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthenticationGuard,OauthGuardService]
  },
  {
    path: 'vins',
    component: VinsComponent,
    canActivate: [AuthenticationGuard,OauthGuardService, LoginGuardService]
  },
  {
    path: 'commands',
    component: CommandsComponent,
    canActivate: [AuthenticationGuard,OauthGuardService, LoginGuardService]
  },
  {
    path: 'manage-vehicles',
    component: ManageVehiclesComponent,
    canActivate: [AuthenticationGuard,OauthGuardService, LoginGuardService]
  },
  {
    path: '',
    component: VinsComponent,
    canActivate: [AuthenticationGuard,OauthGuardService, LoginGuardService]
  },
  {
    path: 'message-center',
    component: MessageCenterComponent,
    canActivate: [AuthenticationGuard,OauthGuardService, LoginGuardService]
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
