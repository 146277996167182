import { Component, OnInit } from '@angular/core';
import {Vin, VinsList} from "./vins.model";
import {Router} from "@angular/router";
import {MessageService} from 'primeng/api';
import {FormField} from "../shared/shared-models.model";
import {handleError, isVinInvalid, showInvalidFieldsToast, showMissingFieldsToast} from "../shared/shared-functions";
import {VinsService} from "./vins.service";
import {Observable} from "rxjs";
import {EventService} from "../shared/event.service";

@Component({
  selector: 'app-vins',
  templateUrl: './vins.component.html',
  styleUrls: ['./vins.component.css'],
  providers: [MessageService, VinsService]
})
export class VinsComponent implements OnInit {
  vinsList: Vin[] = [];
  formFields: Record<string, FormField> = {
    testVin: {
      fieldName: 'VIN Number to Test',
      value: '',
      errorMessage: 'Enter a valid VIN to test.',
      isMissing: false,
      isInvalid: false
    }
  };
  loading: boolean;

  constructor(
    private vinsService: VinsService,
    private router: Router,
    private messageService: MessageService,
    private eventService: EventService) {}

  ngOnInit(): void {
    const isAuLogin = JSON.parse(sessionStorage.getItem('isAULogin'))
    if(!isAuLogin) {
      let observable: Observable<any>;

      this.loading = true;
      observable = this.vinsService.getVins();

      observable.subscribe((response: VinsList) => {
        let vinsList = response.vehicles.$values;
        vinsList.forEach(vinObj => {
          this.vinsList.push({
            vin: vinObj.vin,
            nickName:vinObj.nickName
          });
        });
      }, error => {
        handleError(this.messageService, error, this.router);
      }).add(() => {
        this.loading = false;
      });
    }
  }

  handleVinClick(index: number) {
    sessionStorage.setItem('selectedVin', JSON.stringify(this.vinsList[index]));
    this.notifyVinChangeEvent(this.vinsList[index].vin);
    this.router.navigate(['commands'])
      .then(null, error => {

      });
  }

  handleSubmit() {
    this.checkInvalidVin();
    if (!this.formFields['testVin'].isMissing) {
      if(!this.formFields['testVin'].isInvalid) {
        sessionStorage.setItem('selectedVin', JSON.stringify({vin: this.formFields['testVin'].value, nickName: 'Manual Input'} as Vin));
        this.notifyVinChangeEvent(this.formFields['testVin'].value);
        this.router.navigate(['commands'])
          .then(null, error => {

          });
      } else {
        showInvalidFieldsToast(this.messageService, this.formFields);
      }
    }
    else {
      showMissingFieldsToast(this.messageService, this.formFields);
    }
  }

  checkInvalidVin() {
    this.formFields['testVin'].isMissing = /^\s*$/.test(this.formFields['testVin'].value) || this.formFields['testVin'].value == null;
    this.formFields['testVin'].isInvalid = isVinInvalid(this.formFields['testVin'].value);
  }

  notifyVinChangeEvent(selectedVin: string){
    this.eventService.vinEvent.emit(selectedVin);
  }
}
