import {Component, OnInit, ViewChild} from '@angular/core';
import {DeleteReadMessageRequest, MessageContent, MessageSummary} from "./message-center.model";
import {MessageService} from "primeng/api";
import {Router} from "@angular/router";
import {MessageCenterService} from "./message-center.service";
import {Observable} from "rxjs";
import {handleError} from "../shared/shared-functions";
import {TmcResponse} from "../shared/shared-models.model";
import {Table} from "primeng/table";

@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.css'],
  providers: [MessageCenterService]
})
export class MessageCenterComponent implements OnInit {

  messagesList: MessageSummary[];
  messagesContent: Map<number, MessageContent> = new Map<number, MessageContent>();

  lastRetrievedDate: Date = new Date();
  markAsReadWhenExpanded: boolean = true;
  showReadMessages: boolean = true;

  rowsPerPageOptions: number[] = [10, 25, 50, 100, 500];
  selectedRowsPerPage = 10;
  firstRecord: number = 0;

  showInboxStatusDialog: boolean = false;
  inboxStatusCountRead: number;
  inboxStatusCountUnread: number;

  showJSONDialog: boolean = false;
  showJSONMessageId: number;
  showJSONMessageSummary: string;
  showJSONMessageContent: string;

  loading: boolean;

  constructor(private messageCenterService: MessageCenterService,
              private messageService: MessageService,
              private router: Router) {
  }

  @ViewChild('messageTable') table: Table;

  ngOnInit(): void {
    this.lastRetrievedDate.setHours(0,0,0,0);
    this.lastRetrievedDate.setDate(this.lastRetrievedDate.getDate() - 7);

    this.startCommand('refreshMessages');
  }

  formattedDate(date: Date) {
    return date.toLocaleString('en-us', {year: "numeric", month: "2-digit", day: "2-digit"}) + ' ' +
        date.toLocaleString('en-us', {hour: "2-digit", minute: "2-digit"});
  }

  formattedDateFromString(dateString: string) {
    if (dateString) {
      let date = new Date(dateString);
      return date.toLocaleString('en-us', {year: "numeric", month: "2-digit", day: "2-digit"}) + ' ' +
          date.toLocaleString('en-us', {hour: "2-digit", minute: "2-digit"});
    }

    return null;
  }

  filterShowHideReadMessages(checked: boolean) {
    if (!checked) {
      this.table.filter(false, "isRead", "equals");
    } else {
      this.table.filter(null, "isRead", "equals");
    }
  }

  showJSON(message: MessageSummary) {
    this.showJSONMessageId = message.messageId;
    this.showJSONDialog = true;
    this.showJSONMessageSummary = JSON.stringify(message);
    this.showJSONMessageContent = JSON.stringify(this.messagesContent.get(message.messageId));
  }

  startCommand(command: string, additionalData?: any) {
    let observable: Observable<any>;
    let messageListBody: DeleteReadMessageRequest;

    switch(command) {
      case 'refreshMessages':
        this.messagesList = [];
        this.messagesContent.clear();
        let adjustedDate = null;

        if (this.lastRetrievedDate) {
          adjustedDate = new Date(this.lastRetrievedDate.getTime());
          adjustedDate.setMinutes(this.lastRetrievedDate.getMinutes() - this.lastRetrievedDate.getTimezoneOffset());
        }

        observable = this.messageCenterService.getMessages(adjustedDate);
        break;
      case 'getInboxStatus':
        observable = this.messageCenterService.getInboxStatus();
        break;
      case 'getMessageContent':
        if (this.messagesContent.get(additionalData.messageId)) return;

        observable = this.markAsReadWhenExpanded ? this.messageCenterService.getMessageContentMarkAsRead(additionalData.messageId)
            : this.messageCenterService.getMessageContent(additionalData.messageId);
        break;
      case 'deleteMessage':
        messageListBody = {
          messageIds: [additionalData.messageId.toString()]
        } as DeleteReadMessageRequest;
        observable = this.messageCenterService.deleteMessages(messageListBody);
        break;
      case 'markMessageAsRead':
        messageListBody = {
          messageIds: [additionalData.messageId.toString()]
        } as DeleteReadMessageRequest;
        observable = this.messageCenterService.markMessagesAsRead(messageListBody)
        break;
    }

    if (additionalData) {
      this.runCommand(command, observable, additionalData);
    } else {
      this.runCommand(command, observable);
    }
  }

  runCommand(command: string, observable: Observable<any>, additionalData?: any) {
    this.loading = true;
    observable.subscribe((response: TmcResponse) => {
      switch(command) {
        case 'refreshMessages':
          let resultList = response.responseData['result'].messages;
          resultList.forEach(message => {
            message.messageId = parseInt(message.messageId);
            message.createdDate = new Date(message.createdDate);
            this.messagesList = [...this.messagesList, message];
          });

          this.filterShowHideReadMessages(this.showReadMessages);
          break;
        case 'getInboxStatus':
          this.inboxStatusCountRead = response.responseData['result'].readMessageCount;
          this.inboxStatusCountUnread = response.responseData['result'].unreadMessageCount;
          this.showInboxStatusDialog = true;
          break;
        case 'getMessageContent':
          additionalData.isRead = this.markAsReadWhenExpanded;
          this.messagesContent.set(additionalData.messageId, response.responseData['result']);
          break;
        case 'deleteMessage':
          // Must create a new array object in order to update table properly (splice cannot be used)
          let index = this.messagesList.indexOf(additionalData);
          this.messagesList = this.messagesList.slice(0, index).concat(this.messagesList.slice(index + 1));
          this.messagesContent.delete(additionalData.messageId);
          break;
        case 'markMessageAsRead':
          additionalData.isRead = true;
          break;
      }
    }, error => {
      handleError(this.messageService, error, this.router);
    }).add(() => {
      this.loading = false;
    });
  }
}
