import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../api/api.service";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {
  AUCredentials,
  AuthorizeTMCVinRequest,
  BidirectionalSettingsRequest,
  CommandRequest, EVCommandStatusRequest, GeofenceCreateRequest, GlobalChargeCommandRequest, OEMRequest,
  SaveBatteryThresholdRequest, SaveDepartureTimesRequest,
  SmartChargeActivateRequest,
  SmartChargeMinSOCRequest,
  SmartChargeOptInOptOutRequest,
  SmartChargeResponse,
  SmartChargeUtilityResponse, TrailerLightStatusRequest,
  TypeRequest, VideoStreamResponse, VPOIResponse, VPOIResponseList
} from "./commands.model";
import {TmcResponse} from "../shared/shared-models.model";

@Injectable()
export class CommandsService {

  constructor(private http: HttpClient, private apiService: ApiService) {
  }

  doorLock(vin:string, typeRequest: TypeRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/door/'+vin+'/lock', typeRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  doorUnLock(vin:string, typeRequest: TypeRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/door/'+vin+'/unlock', typeRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  remoteStart(vin: string, typeRequest: TypeRequest): Observable<TmcResponse> {
    return this.apiService.post<TmcResponse>(environment.api_base_url, '/api/v1/remote/' + vin + '/start', typeRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  remoteCancelStart(vin: string, typeRequest: TypeRequest): Observable<TmcResponse> {
    return this.apiService.post<TmcResponse>(environment.api_base_url, '/api/v1/remote/' + vin + '/cancelstart', typeRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  statusUpdate(vin:string, typeRequest: TypeRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/status/'+vin+'/update', typeRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  commandStatus(vin: string, commandId: string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/status/'+ vin + '/update/' + commandId, {"Application-Id": sessionStorage.getItem("appId")});
  }

  heatONOFF(vin:string, heatCoolRequest: CommandRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/heat/'+vin, heatCoolRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  coolONOFF(vin:string, heatCoolRequest: CommandRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/cool/'+vin, heatCoolRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  telemetrySettings(vin:string, telemetrySettingsRequest: CommandRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/telemetry-settings/' + vin, telemetrySettingsRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  getTelemetry(vin:string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/telemetry/' + vin, {"Application-Id": sessionStorage.getItem('appId')});
  }

  listVehiclePermissions(vin:string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/vehicle/' + vin + '/permissions', {"Application-Id": sessionStorage.getItem('appId')});
  }

  authorizeTMCVin(vin:string, authorizeTMCVinRequest: AuthorizeTMCVinRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/authorization/' + vin + '/primary', authorizeTMCVinRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  requestVinAccess(vin:string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/authorization/' + vin + '/retailAccessRequest', {"Application-Id": sessionStorage.getItem('appId')});
  }

  getProviders(zipCode:string): Observable<SmartChargeUtilityResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/smartcharge/' + zipCode + '/providers', {"Application-Id": sessionStorage.getItem('appId')});
  }

  getPlans(utilityId:string): Observable<SmartChargeUtilityResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/smartcharge/' + utilityId + '/plans', {"Application-Id": sessionStorage.getItem('appId')});
  }

  smartChargeActivate(vin:string, smartChargeActivateRequest: SmartChargeActivateRequest): Observable<SmartChargeResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/smartcharge/' + vin + '/activate', smartChargeActivateRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  smartChargeOptInOptOut(vin:string, smartChargeOptInOptOutRequest: SmartChargeOptInOptOutRequest): Observable<SmartChargeResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/smartcharge/' + vin + '/optInOptOut', smartChargeOptInOptOutRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  smartChargeUpdateMinSOC(vin:string, smartChargeMinSOCRequest: SmartChargeMinSOCRequest): Observable<SmartChargeResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/smartcharge/' + vin + '/updateMinSOC', smartChargeMinSOCRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  setBidirectionalSettings(vin: string, bidirectionalSettingsRequest: BidirectionalSettingsRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/smart-charge/' + vin + '/bidirectional-settings', bidirectionalSettingsRequest,{"Application-Id": sessionStorage.getItem('appId')});
  }

  getBidirectionalSettings(vin: string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/smart-charge/' + vin + '/bidirectional-settings', {"Application-Id": sessionStorage.getItem('appId')});
  }

  getCameraViews(vin:string): Observable<VideoStreamResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/videostream/' + vin + '/views', {"Application-Id": sessionStorage.getItem('appId')});
  }

  startStream(vin:string, viewId:string, quality: string): Observable<VideoStreamResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/videostream/' + vin + '/start/'+ viewId, {"videoQuality": quality},{"Application-Id": sessionStorage.getItem('appId')});
  }

  getStreamURL(vin:string, correlationId:string): Observable<VideoStreamResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/videostream/' + vin + '/link/'+ correlationId, {"Application-Id": sessionStorage.getItem('appId')});
  }

  stopStream(vin:string): Observable<VideoStreamResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/videostream/' + vin + '/stop', null,{"Application-Id": sessionStorage.getItem('appId')});
  }

  evRetrieveBatteryThreshold(vin:string): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/ev/batterythreshold/retrieve', {vin: vin}, {"Application-Id": sessionStorage.getItem('appId')});
  }

  evSaveBatteryThreshold(saveBatteryThresholdRequest: SaveBatteryThresholdRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/ev/batterythreshold/save', saveBatteryThresholdRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  evInactivateBatteryThreshold(vin:string): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/ev/batterythreshold/inactivate', {vin: vin}, {"Application-Id": sessionStorage.getItem('appId')});
  }

  evToggleOnDepartureTimes(vin:string): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/ev/departuretimes/toggleOn', {vin: vin}, {"Application-Id": sessionStorage.getItem('appId')});
  }

  evToggleOffDepartureTimes(vin:string): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/ev/departuretimes/toggleOff', {vin: vin}, {"Application-Id": sessionStorage.getItem('appId')});
  }

  evRetrieveDepartureTimes(vin:string): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/ev/departuretimes/retrieve', {vin: vin}, {"Application-Id": sessionStorage.getItem('appId')});
  }

  evSaveDepartureTimes(saveDepartureTimesRequest: SaveDepartureTimesRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/ev/departuretimes/save', saveDepartureTimesRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  evDeleteAllDepartureTimes(vin:string): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/ev/departuretimes/deleteAll', {vin: vin}, {"Application-Id": sessionStorage.getItem('appId')});
  }

  evGlobalChargeStart(globalChargeCommandRequest: GlobalChargeCommandRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/ev/globalcharge/start', globalChargeCommandRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  evGlobalChargeStop(globalChargeCommandRequest: GlobalChargeCommandRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/ev/globalcharge/stop', globalChargeCommandRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  evGlobalChargeCancel(globalChargeCommandRequest: GlobalChargeCommandRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/ev/globalcharge/cancel', globalChargeCommandRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  evGetCommandStatus(evCommandStatusRequest: EVCommandStatusRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/ev/status', evCommandStatusRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  vpoiPlugStatus(vin:string): Observable<VPOIResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/ev/vpoi/'+vin+'/plugstatus', {"Application-Id": sessionStorage.getItem('appId')});
  }

  getChargeLocation(vin:string): Observable<VPOIResponseList> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/ev/vpoi/'+vin+'/chargeLocation', {"Application-Id": sessionStorage.getItem('appId')});
  }

  getSavedLocation(vin:string): Observable<VPOIResponseList> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/ev/vpoi/'+vin+'/savedLocation', {"Application-Id": sessionStorage.getItem('appId')});
  }

  getUnSavedLocation(vin:string): Observable<VPOIResponseList> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/ev/vpoi/'+vin+'/unsavedLocation', {"Application-Id": sessionStorage.getItem('appId')});
  }

  getLastStatus(vin:string, correlationId:string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/ev/vpoi/'+vin+'/lastStatus/'+correlationId, {"Application-Id": sessionStorage.getItem('appId')});
  }

  getChargeLocationById(vin:string, locationId: string): Observable<VPOIResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/ev/vpoi/'+vin+'/chargeStation/'+locationId, {"Application-Id": sessionStorage.getItem('appId')});
  }

  updateChargeStation(vin:string, response: VPOIResponse): Observable<TmcResponse> {
    return this.apiService.put<any>(environment.api_base_url, '/api/v1/ev/vpoi/'+vin+'/chargeStation/update', response,{"Application-Id": sessionStorage.getItem('appId')});
  }

  deleteChargeStation(vin:string, locationId: string): Observable<TmcResponse> {
    return this.apiService.delete<any>(environment.api_base_url, '/api/v1/ev/vpoi/'+vin+'/chargeStation/'+locationId+'/delete',{"Application-Id": sessionStorage.getItem('appId')});
  }

  geofenceGetAllGeofences(vin: string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/geofence/' + vin, {"Application-Id": sessionStorage.getItem('appId')});
  }

  geofenceGetGeofenceByFenceId(vin: string, fenceId: string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/geofence/' + vin + '/' + fenceId, {"Application-Id": sessionStorage.getItem('appId')});
  }

  geofenceCreateGeofence(vin: string, geofenceCreateRequest: GeofenceCreateRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/geofence/' + vin, geofenceCreateRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  getGSByLocation(latitude: string, longitude: string, boundaryRadius:string, regionCode:string): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/getNearByStations?Lat=' + latitude +'&Lon=' + longitude + '&boundaryRadius=' + boundaryRadius +'&regionCode=' + regionCode, null, {"Application-Id": sessionStorage.getItem('appId')});
  }

  getGSByLocationId(vin: string, locationId: string, regionCode: string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/stationsBy/' + locationId +'/' + regionCode, {"Application-Id": sessionStorage.getItem('appId')});
  }

  zoneLightingOn(vin: string, zone: number): Observable<TmcResponse> {
    return this.apiService.put<any>(environment.api_base_url, '/api/v1/zonelighting/' + vin + '/' + zone, "",{"Application-Id": sessionStorage.getItem('appId')});
  }

  zoneLightingOff(vin: string, zone: number): Observable<TmcResponse> {
    return this.apiService.delete<any>(environment.api_base_url, '/api/v1/zonelighting/' + vin + '/' + zone, {"Application-Id": sessionStorage.getItem('appId')});
  }

  zoneLightingOnOffCommandStatus(vin: string, commandId: string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/zonelighting/' + vin + '/status/' + commandId, {"Application-Id": sessionStorage.getItem('appId')});
  }

  zoneLightingActivate(vin: string): Observable<TmcResponse> {
    return this.apiService.put<any>(environment.api_base_url, '/api/v1/zonelighting/activation/' + vin, "", {"Application-Id": sessionStorage.getItem('appId')});
  }

  zoneLightingInactivate(vin: string): Observable<TmcResponse> {
    return this.apiService.delete<any>(environment.api_base_url, '/api/v1/zonelighting/activation/' + vin, {"Application-Id": sessionStorage.getItem('appId')});
  }

  zoneLightingActivateInactivateCommandStatus(vin: string, commandId: string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/zonelighting/activation/' + vin + '/status/' + commandId, {"Application-Id": sessionStorage.getItem('appId')});
  }

  trailerLightCheckActivationStart(vin: string): Observable<TmcResponse> {
    return this.apiService.put<any>(environment.api_base_url, '/api/v1/trailerlightcheck/activation/' + vin, "", {"Application-Id": sessionStorage.getItem('appId')});
  }

  trailerLightCheckActivationStop(vin: string): Observable<TmcResponse> {
    return this.apiService.delete<any>(environment.api_base_url, '/api/v1/trailerlightcheck/activation/' + vin, {"Application-Id": sessionStorage.getItem('appId')});
  }

  trailerLightCheckActivationCommandStatus(vin: string, commandId: string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/trailerlightcheck/activation/' + vin + '/status/' + commandId, {"Application-Id": sessionStorage.getItem('appId')});
  }

  trailerLightCheckStatus(vin: string, trailerLightStatusRequest: TrailerLightStatusRequest): Observable<TmcResponse> {
    return this.apiService.put<any>(environment.api_base_url, '/api/v1/trailerlightcheck/' + vin + '/status', trailerLightStatusRequest, {"Application-Id": sessionStorage.getItem('appId')});
  }

  updatePttbStatus(vin: string): Observable<TmcResponse> {
    return this.apiService.put<any>(environment.api_base_url, '/api/v1/updatePttbStatus/' + vin, "", {"Application-Id": sessionStorage.getItem('appId')});
  }

  updatePttbStatusCommandStatus(vin: string, commandId: string): Observable<TmcResponse> {
    return this.apiService.get<any>(environment.api_base_url, '/api/v1/updatePttbStatus/' + vin + '/status/' + commandId, {"Application-Id": sessionStorage.getItem('appId')});
  }

  paakPlugAndChargeStatus(vin: string, packageCode: string): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/paak/pnc/status/' + vin + '/' + packageCode, "", {"Application-Id": sessionStorage.getItem('appId')});
  }

  haltRsoAlerts(vin: string): Observable<TmcResponse> {
    return this.apiService.put<any>(environment.api_base_url, '/api/v1/haltrsoalerts/' + vin, "", {"Application-Id": sessionStorage.getItem('appId')});
  }

  oemCommands(vin: string, request: OEMRequest): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/vehicle/' + vin + '/oem/commands', request);
  }

  oemModuleProperty(vin: string, request: AUCredentials): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/vehicle/' + vin + '/module-property', request);
  }

  oemCommandStatus(vin: string, commandId: string, request: AUCredentials): Observable<TmcResponse> {
    return this.apiService.post<any>(environment.api_base_url, '/api/v1/oem/'+ vin + '/status/' + commandId, request);
  }
}
