import {Component, OnInit} from '@angular/core';
import {Vin, VinResponse, VinsList} from "../vins/vins.model";
import {MessageService} from "primeng/api";
import {FormField} from "../shared/shared-models.model";
import {isVinInvalid, showInvalidFieldsToast, showMissingFieldsToast, handleError} from "../shared/shared-functions";
import {VinsService} from "../vins/vins.service";
import {Observable} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-manage-vehicles',
  templateUrl: './manage-vehicles.component.html',
  styleUrls: ['./manage-vehicles.component.css'],
  providers: [MessageService, VinsService]
})
export class ManageVehiclesComponent implements OnInit {
  vinsList: Vin[] = [];
  dialogVisible: boolean = false;
  isVinEditable: boolean = false;
  formFields: Record<string, FormField> = {
    nickname: {
      fieldName: 'Nickname',
      value: '',
      errorMessage: 'Enter a valid nickname.',
      isMissing: false,
      isInvalid: false
    },
    vin: {
      fieldName: 'VIN',
      value: '',
      errorMessage: 'Enter a valid VIN.',
      isMissing: false,
      isInvalid: false
    }
  };
  selectedVinIndex: number = -1;
  loading: boolean;

  constructor(private vinsService: VinsService,
              private messageService: MessageService,
              private router: Router) {
  }

  ngOnInit(): void {
    let observable: Observable<any>;
    this.loading = true;
    observable = this.vinsService.getVins();

    observable.subscribe((response: VinsList) => {
      let vinsList = response.vehicles.$values;
      vinsList.forEach(vinObj => {
        this.vinsList.push({
          vin: vinObj.vin,
          nickName: vinObj.nickName
        });
      });
    }, error => {
      handleError(this.messageService, error, this.router);
    }).add(() => {
      this.loading = false;
    });

  }

  showDialog(index: number, isVinEditable: boolean) {
    this.selectedVinIndex = index;
    this.isVinEditable = isVinEditable;
    this.dialogVisible = true;
    if (index === -1) {
      this.formFields['nickname'].value = '';
      this.formFields['vin'].value = '';
    } else {
      this.formFields['nickname'].value = this.vinsList[index].nickName;
      this.formFields['vin'].value = this.vinsList[index].vin;
    }

    // Reset missing status
    this.formFields['nickname'].isMissing = false;
    this.formFields['vin'].isMissing = false;

    // Reset invalid status
    this.formFields['nickname'].isInvalid = false;
    this.formFields['vin'].isInvalid = false;
  }

  handleAddEditVehicle() {
    let error = false;
    this.checkInvalidField('nickname');
    this.checkInvalidField('vin');

    if (Object.values(this.formFields).some(field => field.isMissing)) {
      error = true;
      showMissingFieldsToast(this.messageService, this.formFields);
    }

    if (Object.values(this.formFields).some(field => field.isInvalid)) {
      error = true;
      showInvalidFieldsToast(this.messageService, this.formFields);
    }

    if(error) return;

    let vin = {
      nickName: this.formFields['nickname'].value,
      vin: this.formFields['vin'].value
    };
    let observable: Observable<any>;

    if (this.selectedVinIndex === -1) {
      this.loading = true;
      observable = this.vinsService.addVin(vin);
    } else {
      this.loading = true;
      observable = this.vinsService.updateNickName(vin);
    }

    observable.subscribe((response: VinResponse) => {
      if(response.error != null){
        this.messageService.add({severity: 'error', summary: 'Error: ' +  response.error.statusCode + ' ' + response.error.statusContext, detail: response.error.message, life: 5000});
      } else {
        if (this.selectedVinIndex === -1) {
          this.vinsList.push(vin);
        }
        else {
          this.vinsList[this.selectedVinIndex].nickName = vin.nickName;
          this.vinsList[this.selectedVinIndex].vin = vin.vin;
        }
      }
    }, error => {
      handleError(this.messageService, error, this.router);
    }).add(() => {
      this.loading = false;
    });

    this.dialogVisible = false;
  }

  handleRemoveVehicle(index: number) {
    let vin = this.vinsList[index].vin;
    this.loading = true;
    this.vinsService.deleteVin(vin).subscribe((response: VinResponse) => {
      if(response.error != null){
        this.messageService.add({severity: 'error', summary: 'Error: ' +  response.error.statusCode + ' '+ response.error.statusContext , detail: response.error.message, life: 5000});
      } else {
        this.vinsList.splice(index, 1);
      }
    }, error => {
      handleError(this.messageService, error, this.router);
    }).add(() => {
      this.loading = false;
    });
  }

  checkInvalidField(inputField: string) {
    if (this.formFields[inputField]) {
      const value = this.formFields[inputField].value;
      this.formFields[inputField].isMissing = /^\s*$/.test(value) || value == null;

      if (inputField === 'vin') {
        this.formFields[inputField].isInvalid = isVinInvalid(value);
      }
    }
  }
}
