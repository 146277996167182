import {Component, OnInit, SecurityContext} from '@angular/core';
import {
  Address,
  AUCredentials,
  AuthorizeTMCVinRequest,
  BidirectionalSettingsRequest,
  ChargeProfile,
  ChargeSchedules,
  CommandRequest,
  DepartureTimesCalendarDay,
  DepartureTimesScheduleFromCloud,
  EVCommandStatusRequest,
  GeofenceCreateRequest,
  GeofenceDetails,
  GlobalChargeCommandRequest,
  GlobalChargeCommandType,
  OEMProperties,
  OEMRequest,
  Options,
  SaveBatteryThresholdRequest,
  SaveDepartureTimesRequest,
  SettingType,
  SmartChargeActivateRequest,
  SmartChargeMinSOCRequest,
  SmartChargeOptInOptOutRequest,
  SmartChargeUtilityResponse,
  TrailerLightStatusRequest,
  Type,
  TypeRequest,
  UtilityDetails,
  VideoStreamResponse,
  VPOIErrorResponse,
  VPOIResponse,
  VPOIResponseList
} from "./commands.model";
import {CommandsService} from "./commands.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {Observable, switchMap, takeWhile, timer} from "rxjs";
import {handleError, leadingZeros, showMissingFieldsToast} from "../shared/shared-functions";
import {Router} from "@angular/router";
import {DropdownOption, FormField, TmcResponse} from "../shared/shared-models.model";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.css'],
  providers: [CommandsService]
})
export class CommandsComponent implements OnInit {

  results: string | undefined;
  menuOption: Options[] = [];
  selectedVin: string | undefined;
  selectedVinNickname: string | undefined;
  loading: boolean;
  telemetrySettingsDialogVisible: boolean;
  telemetrySettings: string;
  authorizeTMCVinDialogVisible: boolean;
  authorizeTMCVinOdometer: FormField = {
      fieldName: 'Actual Mileage',
      value: null,
      errorMessage: 'Enter a valid odometer reading.',
      isMissing: false
    } as FormField;
  scActivateDialogVisible: boolean;
  scMinSOCDialogVisible: boolean;
  scSaveBDSettingDialogVisible: boolean;
  isVideoStreamVisible: boolean;
  scMinSOC: FormField = {
    fieldName: 'Min SOC (%) :',
    value: null,
    errorMessage: 'Enter a valid value.',
    isMissing: false
  } as FormField;
  modeOptions: DropdownOption[] = [];
  providerNameOptions: DropdownOption[] = [];
  planNameOptions: DropdownOption[] = [];
  videoStreamViewOptions: DropdownOption[] = [];
  saveBDSetting: Record<string, FormField> = {
    mode: {
      fieldName: 'Mode',
      value: 'MANUAL',
      errorMessage: 'Select a Mode.',
      isMissing: false
    },
    rangeReserve: {
      fieldName: 'Range Reserve',
      value: null,
      errorMessage: 'Enter a valid Range Reserve.',
      isMissing: false
    },
    vin: {
      fieldName: 'VIN',
      value: null,
      errorMessage: 'Enter a valid VIN.',
      isMissing: false
    }
  };
  scActivate: Record<string, FormField> = {
    address1: {
      fieldName: 'Address1',
      value: null,
      errorMessage: 'Enter a valid Address1.',
      isMissing: false
    },
    address2: {
      fieldName: 'Address2',
      value: null,
      errorMessage: 'Enter a valid Address2.',
      isMissing: false
    },
    city: {
      fieldName: 'City',
      value: null,
      errorMessage: 'Enter a valid city.',
      isMissing: false
    },
    state: {
      fieldName: 'State',
      value: null,
      errorMessage: 'Enter a valid state.',
      isMissing: false
    },
    zipcode: {
      fieldName: 'ZipCode',
      value: null,
      errorMessage: 'Enter a valid zipcode.',
      isMissing: false
    },
    country: {
      fieldName: 'Country',
      value: null,
      errorMessage: 'Enter a valid country.',
      isMissing: false
    },
    minSOC: {
      fieldName: 'Min SOC',
      value: null,
      errorMessage: 'Enter a valid SOC.',
      isMissing: false
    },
    providerName: {
      fieldName: 'Provider Name',
      value: null,
      errorMessage: 'Select a Provider.',
      isMissing: false
    },
    planName: {
      fieldName: 'Plan Name',
      value: null,
      errorMessage: 'Select a Plan.',
      isMissing: false
    }
  };
  videoStreamView: FormField = {
    fieldName: 'View',
    value: null,
    errorMessage: 'Select a view.',
    isMissing: false
  } as FormField;
  videoStreamLink: SafeResourceUrl;
  videoStreamId: string;
  saveBatteryThresholdDialogVisible: boolean;
  saveBatteryThreshold: FormField = {
    fieldName: 'Battery Threshold for Notification',
    value: null,
    errorMessage: 'Enter a valid battery threshold.',
    isMissing: false
  } as FormField;
  toggleDepartureTimesDialogVisible: boolean;
  toggleDepartureTimesSettings: string;
  departureTimesDialogVisible: boolean;
  departureTimesNumPerDay = Array.from(Array(2).keys());
  departureTimesDayOfTheWeek: string[] = ['Sunday', 'Monday', 'Tuesday',
                                'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  departureTimesHrOptions: DropdownOption[];
  departureTimesMinOptions: DropdownOption[];
  departureTimesTempOptions: DropdownOption[];
  departureTimesCalendarDaysList: DepartureTimesCalendarDay[];
  departureTimesSaveDepartureTimesRequest: SaveDepartureTimesRequest;

  globalChargeDialogVisible: boolean;
  globalChargeLastCorrelationId: number;

  vpoiCorrelationId: string;
  chargeStationList: VPOIResponse[];
  chargeStationDialogVisible: boolean;
  unsavedChargeStationDialogVisible: boolean;
  savedChargeStationDialogVisible: boolean;
  chargeLevels: DropdownOption[];
  chargeTimings: string[];
  defaultChargeProfile: ChargeProfile[];

  geofenceGetByFenceIdDialogVisible: boolean;
  geofenceFenceId: FormField = {
    fieldName: 'Fence ID',
    value: null,
    errorMessage: 'Enter a valid fence ID.',
    isMissing: false
  } as FormField;

  geofenceCreateDialogVisible: boolean;
  geofenceCreateRequest: Record<string, FormField> = {
    fenceName: {
      fieldName: 'Fence Name',
      value: null,
      errorMessage: 'Enter a valid fence name.',
      isMissing: false
    },
    fenceTypeCode: {
      fieldName: 'Fence Type Code',
      value: 'Circle',
      errorMessage: 'Enter a valid fence type code.',
      isMissing: false
    },
    fenceValue: {
      fieldName: 'Radius',
      value: null,
      errorMessage: 'Enter a valid radius.',
      isMissing: false
    },
    unitOfMeasurement: {
      fieldName: 'Unit of Measurement',
      value: 'm',
      errorMessage: 'Enter a valid unit of measurement.',
      isMissing: false
    },
    featurePackageCode: {
      fieldName: 'Feature Package Code',
      value: 'GEOF_PK00201',
      errorMessage: 'Enter a valid feature package code.',
      isMissing: false
    },
    latitude: {
      fieldName: 'Latitude',
      value: null,
      errorMessage: 'Enter a valid latitude.',
      isMissing: false
    },
    longitude: {
      fieldName: 'Longitude',
      value: null,
      errorMessage: 'Enter a valid longitude.',
      isMissing: false
    },
    altitude: {
      fieldName: 'Altitude',
      value: null,
      errorMessage: 'Enter a valid altitude.',
      isMissing: false
    }
  };
  gsByLocationRequest: Record<string, FormField> = {
    latitude: {
      fieldName: 'Latitude',
      value: null,
      errorMessage: 'Enter a valid latitude.',
      isMissing: false
    },
    longitude: {
      fieldName: 'Longitude',
      value: null,
      errorMessage: 'Enter a valid longitude.',
      isMissing: false
    },
    radius: {
      fieldName: 'Radius',
      value: null,
      errorMessage: 'Enter a valid radius.',
      isMissing: false
    },
    regionCode: {
      fieldName: 'Region Code',
      value: 'NA',
      errorMessage: 'Enter a valid region code.',
      isMissing: false
    }
  };
  gsByLocationIdRequest: Record<string, FormField> = {
    locationId: {
      fieldName: 'Location Id',
      value: null,
      errorMessage: 'Enter a valid location Id.',
      isMissing: false
    },
    regionCode: {
      fieldName: 'Region Code',
      value: 'NA',
      errorMessage: 'Enter a valid region code.',
      isMissing: false
    }
  };
  stationsByLocationDialogVisible: boolean;
  stationsByLocationIdDialogVisible: boolean;
  regionCodeOptions: Options[] = [];

  zoneLightingOnOffDialogVisible: boolean;
  zoneLightingOnOffLastCommandId: string;
  zoneLightingZone: FormField = {
    fieldName: 'Zone',
    value: null,
    errorMessage: 'Select a zone.',
    isMissing: false
  } as FormField;
  zoneOptions: DropdownOption[];
  zoneLightingActivateInactivateDialogVisible: boolean;
  zoneLightingActivateInactivateLastCommandId: string;
  trailerLightCheckDialogVisible: boolean;
  trailerLightCheckLastCommandId: string;
  plugAndChargeDialogVisible: boolean;
  plugAndChargePackageCode: FormField = {
    fieldName: 'Package Code',
    value: null,
    errorMessage: 'Enter a valid package code.',
    isMissing: false
  } as FormField;
  authorizationStatusChangeDialogVisible: boolean;
  deviceDiagnosticDialogVisible: boolean;
  softResetDialogVisible: boolean;
  oemRequest: OEMRequest;
  enableTelemetry: boolean;
  deviceDiagnosticRequest: Record<string, FormField> = {
    logType: {
      fieldName: 'Log Type',
      value: null,
      errorMessage: 'Enter a valid Log Type',
      isMissing: false
    },
    byteStreamUri: {
      fieldName: 'Byte Stream URI',
      value: null,
      errorMessage: 'Enter a valid Byte Stream URI',
      isMissing: false
    }
  };
  softResetRequest: Record<string, FormField> = {
    moduleName: {
      fieldName: 'Enter Module Name',
      value: null,
      errorMessage: 'Enter a valid Module Name',
      isMissing: false
    }
  };
  auCredentials: AUCredentials = {} as AUCredentials;
  isAuLogin: boolean = false;
  vehicleCommandStatusDialogVisible: boolean;
  oemCommandStatusDialogVisible: boolean;
  vehicleCmdId: FormField = {
    fieldName: 'Command Id:',
    value: null,
    errorMessage: 'Enter a valid value.',
    isMissing: false
  } as FormField;
  oemCmdId: FormField = {
    fieldName: 'Command Id:',
    value: null,
    errorMessage: 'Enter a valid value.',
    isMissing: false
  } as FormField;

  constructor(
    private router: Router,
    private commandsService: CommandsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    sessionStorage.removeItem('commandResults');
    this.loading = false;
    this.telemetrySettingsDialogVisible = false;
    this.telemetrySettings = '';
    this.authorizeTMCVinDialogVisible = false;
    this.results = "";
    this.menuOption = [];
    this.regionCodeOptions = [];
    this.scSaveBDSettingDialogVisible = false;
    this.scMinSOCDialogVisible = false;
    this.scActivateDialogVisible = false;
    this.isVideoStreamVisible = false;
    this.vehicleCommandStatusDialogVisible = false;
    this.oemCommandStatusDialogVisible = false;
    this.modeOptions = [
      {label: 'MANUAL', value: 'MANUAL'} as DropdownOption,
      {label: 'AUTO', value: 'AUTO'} as DropdownOption,
      {label: 'OFF', value: 'OFF'} as DropdownOption
    ];
    this.regionCodeOptions = [
      {label: 'NA', value: 'NA'} as DropdownOption,
      {label: 'CN', value: 'CN'} as DropdownOption,
      {label: 'EU', value: 'EU'} as DropdownOption
    ];
    this.videoStreamLink = null;
    this.saveBatteryThresholdDialogVisible = false;
    this.toggleDepartureTimesDialogVisible = false;
    this.departureTimesDialogVisible = false;
    this.departureTimesHrOptions = Array.from(Array(24).keys()).map(x => ({label: leadingZeros(x), value: x} as DropdownOption));
    this.departureTimesMinOptions = Array.from(Array(12).keys()).map(x => ({label: leadingZeros(x * 5), value: x * 5} as DropdownOption));
    this.departureTimesTempOptions = [
      {label: 'Off', value: 'Off'} as DropdownOption,
      {label: 'Cold', value: 'Low'} as DropdownOption,
      {label: 'Medium', value: 'Medium'} as DropdownOption,
      {label: 'Warm', value: 'High'} as DropdownOption
    ];
    this.resetDepartureTimesCalendarDaysList();
    this.globalChargeDialogVisible = false;
    this.globalChargeLastCorrelationId = null;
    this.geofenceGetByFenceIdDialogVisible = false;
    this.geofenceCreateDialogVisible = false;
    this.stationsByLocationDialogVisible = false;
    this.stationsByLocationIdDialogVisible = false;
    this.zoneLightingOnOffDialogVisible = false;
    this.zoneLightingOnOffLastCommandId = null;
    this.zoneOptions = [
      {label: '0', value: 0} as DropdownOption,
      {label: '1', value: 1} as DropdownOption,
      {label: '2', value: 2} as DropdownOption,
      {label: '3', value: 3} as DropdownOption,
      {label: '4', value: 4} as DropdownOption
    ];
    this.zoneLightingActivateInactivateDialogVisible = false;
    this.zoneLightingActivateInactivateLastCommandId = null;
    this.trailerLightCheckDialogVisible = false;
    this.trailerLightCheckLastCommandId = null;
    this.plugAndChargeDialogVisible = false;
    this.plugAndChargePackageCode.value = 'EVPC_PK00200';
    this.authorizationStatusChangeDialogVisible = false;
    this.deviceDiagnosticDialogVisible = false;
    this.softResetDialogVisible = false;
    this.enableTelemetry = null;

    const selectedVinString = sessionStorage.getItem('selectedVin');
    if(sessionStorage.getItem('commandResults')){
      this.results = JSON.parse(decodeURIComponent(atob(sessionStorage.getItem('commandResults')))) as string;
    }
    if (selectedVinString) {
      const selectedVinObj = JSON.parse(selectedVinString);
      this.selectedVin = selectedVinObj.vin;
      this.selectedVinNickname = selectedVinObj.nickName;
      this.saveBDSetting['vin'].value = this.selectedVin;
    } else {
      this.messageService.add({severity: 'error', summary: 'No VIN Selected', detail: "Please enter a VIN or choose one from the list."});
      this.router.navigate(['/vins']);
    }

    this.departureTimesSaveDepartureTimesRequest = {
      vin: this.selectedVin,
      isEnabled: null,
      goTimesScheduleCloudData: {
        calendarDaysList: null
      }
    } as SaveDepartureTimesRequest;

    this.chargeStationList =[];
    this.chargeLevels = [
      {label: '100', value: '100'} as DropdownOption,
      {label: '95', value: '95'} as DropdownOption,
      {label: '90', value: '90'} as DropdownOption,
      {label: '85', value: '85'} as DropdownOption,
      {label: '80', value: '80'} as DropdownOption,
      {label: '70', value: '70'} as DropdownOption,
      {label: '60', value: '60'} as DropdownOption,
      {label: '50', value: '50'} as DropdownOption
    ];

    this.chargeTimings = ['07:00', '07:30', '08:00',
      '08:30', '09:00', '09:30',
      '10:00', '10:30', '11:00', '11:30',
      '12:00', '12:30', '13:00',
      '13:30', '14:00', '14:30',
      '15:00', '15:30', '16:00',
      '16:30', '17:00', '17:30',
      '18:00', '18:30', '19:00',
      '19:30', '20:00', '20:30',
      '21:00', '21:30', '22:00',
      '22:30', '23:00', '23:30',
      '00:00', '00:30', '01:00',
      '01:30', '02:00', '02:30',
      '03:00', '03:30', '04:00',
      '04:30', '05:00', '05:30',
      '06:00', '06:30'
    ];
    this.defaultChargeProfile = [];
    this.isAuLogin = JSON.parse(sessionStorage.getItem('isAULogin'))
    if(this.isAuLogin) {
      this.auCredentials = JSON.parse(decodeURIComponent(atob(sessionStorage.getItem('loginUser')))) as AUCredentials;
    }
  }

  getCmdAndCtrlOptions() {
    const CmdAndCtrlOptions = [
      {label: 'Remote Start', value: 'Remote_Start'} as Options,
      {label: 'Cancel Start', value: 'Cancel_Start'} as Options,
      {label: 'Door Lock', value: 'Door_Lock'} as Options,
      {label: 'Door Unlock', value: 'Door_Unlock'} as Options,
      {label: 'Heat ON', value: 'Heat_ON'} as Options,
      {label: 'Heat OFF', value: 'Heat_OFF'} as Options,
      {label: 'Cool ON', value: 'Cool_ON'} as Options,
      {label: 'Cool OFF', value: 'Cool_OFF'} as Options,
      {label: 'Telemetry Settings', value: 'Telemetry_Settings'} as Options,
      {label: 'Status Update', value: 'Status_Update'} as Options
    ];

    this.menuOption = CmdAndCtrlOptions;
  }

  getPermissionsOptions() {
    const PermissionsCommandsOptions = [
      {label: 'List Vehicle\'s Permissions', value: 'List_Vehicles_Permissions'} as Options,
      {label: 'Authorize TMC Vin', value: 'Authorize_TMC_Vin'} as Options,
      {label: 'Request Access to Vin', value: 'Request_Access_to_Vin'} as Options
    ];

    this.menuOption = PermissionsCommandsOptions;
  }

  getCommandInfoOptions() {
    const CommandInfoOptions = [
      {label: 'Get Vehicle Command Status', value: 'Get_Vehicle_Command_Status'} as Options,
      {label: 'Get OEM Command Status', value: 'Get_OEM_Command_Status'} as Options
    ];

    this.menuOption = CommandInfoOptions;
  }

  getOEMCommandsOptions() {
    const OEMCommandsOptions = [
      {label: 'Authorization Status Change', value: 'Authorization_Status_Change'} as Options,
      {label: 'Validate Vehicle Claim', value: 'Validate_Vehicle_Claim'} as Options,
      {label: 'Soft Reset Command', value: 'Soft_Reset_Command'} as Options,
      {label: 'Get Module Property', value: 'Get_Module_Property'} as Options,
      {label: 'Module Discovery', value: 'Module_Discovery'} as Options,
      {label: 'Device Diagnostic', value: 'Device_Diagnostic'} as Options,
      {label: 'Master Reset Command', value: 'Master_Reset_Command'} as Options
    ];

    this.menuOption = OEMCommandsOptions;
  }

  getSmartChargeOptions() {
    const SmartChargeOptions = [
      {label: 'SC Activate', value: 'SC_Activate'} as Options,
      {label: 'SC OptIn', value: 'SC_OptIn'} as Options,
      {label: 'SC OptOut', value: 'SC_OptOut'} as Options,
      {label: 'SC Min SOC', value: 'SC_Min_SOC'} as Options,
      {label: 'SC Get Bidirectional Settings', value: 'SC_Get_Bidirectional_Settings'} as Options,
      {label: 'SC Save Bidirectional Settings', value: 'SC_Save_Bidirectional_Settings'} as Options
    ];

    this.menuOption = SmartChargeOptions;
  }

  getEVOptions() {
    const EVOptions = [
      {label: 'Get Battery Threshold', value: 'Get_Battery_Threshold'} as Options,
      {label: 'Save Battery Threshold', value: 'Save_Battery_Threshold'} as Options,
      {label: 'Inactivate Battery Threshold', value: 'Inactivate_Battery_Threshold'} as Options,
      {label: 'Toggle On/Off Departure Times', value: 'Toggle_Departure_Times'} as Options,
      {label: 'CEVS - Departure Times', value: 'CEVS_Departure_Times'} as Options,
      {label: 'GSSC Start/Stop', value: 'Global_Charge'} as Options,
      {label: 'VPOI-Plug Status', value: 'VPOI_Plug_Status'} as Options,
      {label: 'VPOI-Charge Locations', value: 'VPOI_Charge_Locations'} as Options,
      {label: 'VPOI-Get Saved Locations', value: 'VPOI_Get_Saved_Locations'} as Options,
      {label: 'VPOI-Get Unsaved Locations', value: 'VPOI_Get_Unsaved_Locations'} as Options,
      {label: 'VPOI-Get Last Status', value: 'VPOI_Get_Last_Status'} as Options
    ];

    this.menuOption = EVOptions;
  }

  getGeofenceOptions() {
    const GeofenceOptions = [
      {label: 'Get All Geofences', value: 'Get_All_Geofences'} as Options,
      {label: 'Get Geofence by Fence ID', value: 'Get_Geofence_by_Fence_ID'} as Options,
      {label: 'Create Geofence', value: 'Create_Geofence'} as Options
    ];

    this.menuOption = GeofenceOptions;
  }

  getFB5Options() {
    const FB5Options = [
      {label: 'NearBy GS by Location', value: 'NearBy_GS_by_Location'} as Options,
      {label: 'GS by LocationId', value: 'GS_by_LocationId'} as Options,
      {label: 'Zone Lighting On/Off', value: 'FB5_Zone_Lighting_On_Off'} as Options,
      {label: 'Zone Lighting Activate/\nInactivate', value: 'FB5_Zone_Lighting_Activate_Inactivate'} as Options,
      {label: 'Trailer Light Check', value: 'FB5_Trailer_Light_Check'} as Options,
      {label: 'PttB Update Status', value: 'FB5_PttB_Update_Status'} as Options,
      {label: 'Plug & Charge Toggle', value: 'FB5_Plug_and_Charge'} as Options,
      {label: 'Send RSOA Halt Command', value: 'FB5_RSOA_Halt_Command'} as Options
    ];

    this.menuOption = FB5Options;
  }

  clearResults() {
    this.results = "";
    sessionStorage.removeItem('commandResults');
  }

  handleSetTelemetrySettings() {
    this.telemetrySettingsDialogVisible = false;
    let telemetrySettingsRequest = {
      type: Type.TELEMETRY_SETTINGS,
      properties: {
        setting: this.telemetrySettings
      }
    } as CommandRequest;
    this.runCommand("Telemetry_Settings", this.commandsService.telemetrySettings(this.selectedVin, telemetrySettingsRequest), false);
  }

  closeTelemetrySettings() {
    this.telemetrySettings = null;
  }

  checkMissingAuthorizeTMCVinOdometer() {
    this.authorizeTMCVinOdometer.isMissing = this.authorizeTMCVinOdometer.value == null;
    let formField = {authorizeTMCVinOdometer: this.authorizeTMCVinOdometer} as Record<string, FormField>;

    if (this.authorizeTMCVinOdometer.isMissing) {
      showMissingFieldsToast(this.messageService, formField);
    }
  }

  handleAuthorizeTMCVin() {
    this.checkMissingAuthorizeTMCVinOdometer();
    if (!this.authorizeTMCVinOdometer.isMissing) {
      this.authorizeTMCVinDialogVisible = false;
      let authorizeTMCVinRequest = {
        odometerReading: this.authorizeTMCVinOdometer.value
      } as AuthorizeTMCVinRequest;
      this.runCommand("Authorize_TMC_Vin", this.commandsService.authorizeTMCVin(this.selectedVin, authorizeTMCVinRequest), false);
    }
  }

  closeAuthorizeTMCVin() {
    this.authorizeTMCVinOdometer.value = null;
    this.authorizeTMCVinOdometer.isMissing = false;
  }

  checkMissingSaveBatteryThreshold() {
    this.saveBatteryThreshold.isMissing = this.saveBatteryThreshold.value == null;
    let formField = {saveBatteryThreshold: this.saveBatteryThreshold} as Record<string, FormField>;

    if (this.saveBatteryThreshold.isMissing) {
      showMissingFieldsToast(this.messageService, formField);
    }
  }

  handleSaveBatteryThreshold() {
    this.checkMissingSaveBatteryThreshold();
    if (!this.saveBatteryThreshold.isMissing) {
      this.saveBatteryThresholdDialogVisible = false;
      let saveBatteryThresholdRequest = {
        vin: this.selectedVin,
        value: this.saveBatteryThreshold.value
      } as SaveBatteryThresholdRequest;
      this.runCommand("Save_Battery_Threshold", this.commandsService.evSaveBatteryThreshold(saveBatteryThresholdRequest), false);
    }
  }

  closeSaveBatteryThreshold() {
    this.saveBatteryThreshold.value = null;
    this.saveBatteryThreshold.isMissing = false;
  }

  showInactivateBatteryThresholdConfirmDialog() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to inactivate the battery threshold for ' + this.selectedVin + '?',
      accept: () => {
        this.handleInactivateBatteryThreshold();
      }
    });
  }

  handleInactivateBatteryThreshold() {
    this.runCommand("Inactivate_Battery_Threshold", this.commandsService.evInactivateBatteryThreshold(this.selectedVin), false);
  }

  handleToggleDepartureTimes() {
    this.toggleDepartureTimesDialogVisible = false;
    let observable: Observable<any>;
    observable = (this.toggleDepartureTimesSettings === "On") ? this.commandsService.evToggleOnDepartureTimes(this.selectedVin)
      : this.commandsService.evToggleOffDepartureTimes(this.selectedVin);

    this.runCommand("Toggle_Departure_Times - " + this.toggleDepartureTimesSettings, observable, false);
  }

  closeToggleDepartureTimes() {
    this.toggleDepartureTimesSettings = null;
  }

  resetDepartureTimesCalendarDaysList() {
    this.departureTimesCalendarDaysList = Array.from(Array(7).keys()).map(x => (
      {
        calendarDay: x,
        scheduleFromCloudList: [
          {
            hr: null,
            min: null,
            preConditioningTemp: null
          } as DepartureTimesScheduleFromCloud,
          {
            hr: null,
            min: null,
            preConditioningTemp: null
          } as DepartureTimesScheduleFromCloud
        ]
      } as DepartureTimesCalendarDay
    ));
  }

  setDepartureTimesCalendarDaysList(response: TmcResponse) {
    if (response.code == 200) {
      let responseData = (response.responseData as unknown as SaveDepartureTimesRequest);
      responseData.goTimesScheduleCloudData.calendarDaysList.forEach(calendarDay => {
        this.departureTimesCalendarDaysList[calendarDay.calendarDay] = calendarDay;
      });
      this.departureTimesSaveDepartureTimesRequest.isEnabled = responseData.isEnabled;
    }
  }

  retrieveDepartureTimes() {
    this.resetDepartureTimesCalendarDaysList();
    this.runCommand("CEVS_Departure_Times - Retrieve", this.commandsService.evRetrieveDepartureTimes(this.selectedVin), false);
  }

  handleClearDepartureTime(dayIndex: number, timeIndex: number) {
    this.departureTimesCalendarDaysList[dayIndex].scheduleFromCloudList[timeIndex].hr = null;
    this.departureTimesCalendarDaysList[dayIndex].scheduleFromCloudList[timeIndex].min = null;
  }

  handleSaveDepartureTimes() {
    this.departureTimesDialogVisible = false;
    // Replace nulls in hr and min with 24 and 60, respectively
    this.departureTimesCalendarDaysList.forEach((day, dayIndex) => {
      day.scheduleFromCloudList.forEach((time, timeIndex) => {
        let hour = this.departureTimesCalendarDaysList[dayIndex].scheduleFromCloudList[timeIndex].hr;
        let min = this.departureTimesCalendarDaysList[dayIndex].scheduleFromCloudList[timeIndex].min;

        if (hour == null && min == null) {
          this.departureTimesCalendarDaysList[dayIndex].scheduleFromCloudList[timeIndex].hr = 24;
          this.departureTimesCalendarDaysList[dayIndex].scheduleFromCloudList[timeIndex].min = 60;
        }
      });
    });

    this.departureTimesSaveDepartureTimesRequest.goTimesScheduleCloudData.calendarDaysList = this.departureTimesCalendarDaysList.map(item => item);
    this.runCommand("CEVS_Departure_Times - Save", this.commandsService.evSaveDepartureTimes(this.departureTimesSaveDepartureTimesRequest), false);
  }

  handleDeleteAllDepartureTimes() {
    this.departureTimesDialogVisible = false;
    this.runCommand("CEVS_Departure_Times - Delete All", this.commandsService.evDeleteAllDepartureTimes(this.selectedVin), false);
  }

  closeGlobalCharge() {
    this.globalChargeDialogVisible = false;
  }

  handleGlobalCharge(command: string) {
    this.closeGlobalCharge();
    let observable: Observable<any>;
    let globalChargeCommandRequest = {
      vin: this.selectedVin
    } as GlobalChargeCommandRequest;
    let evCommandStatusRequest = {
      vin: this.selectedVin,
      correlationId: this.globalChargeLastCorrelationId
    } as EVCommandStatusRequest;

    if (command === 'Start') {
      globalChargeCommandRequest.commandType = GlobalChargeCommandType.GLOBAL_START;
      observable = this.commandsService.evGlobalChargeStart(globalChargeCommandRequest);
    } else if (command === 'Stop') {
      globalChargeCommandRequest.commandType = GlobalChargeCommandType.GLOBAL_PAUSE;
      observable = this.commandsService.evGlobalChargeStop(globalChargeCommandRequest);
    } else if (command === 'Cancel') {
      globalChargeCommandRequest.commandType = GlobalChargeCommandType.GLOBAL_CANCEL;
      observable = this.commandsService.evGlobalChargeCancel(globalChargeCommandRequest);
    } else if(command === 'Last Command Status') {
      observable = this.commandsService.evGetCommandStatus(evCommandStatusRequest);
    }

    this.runCommand("Global_Charge - " + command, observable, false);
  }

  checkMissingGeofenceFenceId() {
    this.geofenceFenceId.isMissing = this.geofenceFenceId.value == null;
    let formField = {geofenceFenceId: this.geofenceFenceId} as Record<string, FormField>;

    if (this.geofenceFenceId.isMissing) {
      showMissingFieldsToast(this.messageService, formField);
    }
  }

  handleSubmitGeofenceGetByFenceId() {
    this.checkMissingGeofenceFenceId();
    if (!this.geofenceFenceId.isMissing) {
      this.geofenceGetByFenceIdDialogVisible = false;
      this.runCommand("Get_Geofence_by_Fence_ID", this.commandsService.geofenceGetGeofenceByFenceId(this.selectedVin, this.geofenceFenceId.value), false);
    }
  }

  closeGeofenceGetByFenceId() {
    this.geofenceFenceId.value = null;
    this.geofenceFenceId.isMissing = false;
  }

  checkMissingGeofenceCreateRequest(inputField: string) {
    if (this.geofenceCreateRequest[inputField]) {
      const value = this.geofenceCreateRequest[inputField].value;
      this.geofenceCreateRequest[inputField].isMissing = /^\s*$/.test(value) || value == null;
    }
  }

  handleSubmitGeofenceCreate() {
    Object.keys(this.geofenceCreateRequest).forEach(fieldName => this.checkMissingGeofenceCreateRequest(fieldName));
    if (Object.values(this.geofenceCreateRequest).every(field => !field.isMissing)) {
      this.geofenceCreateDialogVisible = false;
      let geofenceCreateRequest = {
        fence_name: this.geofenceCreateRequest['fenceName'].value,
        fencetype_code: this.geofenceCreateRequest['fenceTypeCode'].value,
        fence_value: this.geofenceCreateRequest['fenceValue'].value,
        unit_of_measurement: this.geofenceCreateRequest['unitOfMeasurement'].value,
        feature_package_code: this.geofenceCreateRequest['featurePackageCode'].value,
        geofence_details: [{
          lat: this.geofenceCreateRequest['latitude'].value,
          lon: this.geofenceCreateRequest['longitude'].value,
          alt: this.geofenceCreateRequest['altitude'].value
        } as GeofenceDetails],
        notification_status: true
      } as GeofenceCreateRequest;
      this.runCommand("Create_Geofence", this.commandsService.geofenceCreateGeofence(this.selectedVin, geofenceCreateRequest), false);
    } else {
      showMissingFieldsToast(this.messageService, this.geofenceCreateRequest);
    }
  }

  closeGeofenceCreateDialog() {
    Object.values(this.geofenceCreateRequest).forEach(field => field.value = null);
    this.geofenceCreateRequest['fenceTypeCode'].value = 'Circle';
    this.geofenceCreateRequest['unitOfMeasurement'].value = 'm';
    this.geofenceCreateRequest['featurePackageCode'].value = 'GEOF_PK00201';
    Object.values(this.geofenceCreateRequest).forEach(field => field.isMissing = false);
  }

  checkMissingZoneLightingZone() {
    this.zoneLightingZone.isMissing = this.zoneLightingZone.value == null;
    let formField = {zoneLightingZone: this.zoneLightingZone} as Record<string, FormField>;

    if (this.zoneLightingZone.isMissing) {
      showMissingFieldsToast(this.messageService, formField);
    }
  }

  handleZoneLightingOnOff(command: string) {
    let observable: Observable<any>;

    if (command === 'Last Command Status') {
      observable = this.commandsService.zoneLightingOnOffCommandStatus(this.selectedVin, this.zoneLightingOnOffLastCommandId);
    } else {
      this.checkMissingZoneLightingZone();
      if (!this.zoneLightingZone.isMissing) {
        if (command === 'ON') {
          observable = this.commandsService.zoneLightingOn(this.selectedVin, this.zoneLightingZone.value);
        } else if (command === 'OFF') {
          observable = this.commandsService.zoneLightingOff(this.selectedVin, this.zoneLightingZone.value);
        }
      } else {
        return;
      }
    }

    this.closeZoneLightingOnOffDialog();
    this.runCommand("Zone_Lighting_On_Off - " + command, observable, false);
  }

  closeZoneLightingOnOffDialog() {
    this.zoneLightingOnOffDialogVisible = false;
    this.zoneLightingZone.value = null;
    this.zoneLightingZone.isMissing = false;
  }

  handleZoneLightingActivateInactivate(command: string) {
    this.closeZoneLightingActivateInactivateDialog();
    let observable: Observable<any>;

    if (command === 'Activate') {
      observable = this.commandsService.zoneLightingActivate(this.selectedVin);
    } else if (command === 'Inactivate') {
      observable = this.commandsService.zoneLightingInactivate(this.selectedVin);
    } else if(command === 'Last Command Status') {
      observable = this.commandsService.zoneLightingActivateInactivateCommandStatus(this.selectedVin, this.zoneLightingActivateInactivateLastCommandId);
    }

    this.runCommand("Zone_Lighting_Activate_Inactivate - " + command, observable, false);
  }

  closeZoneLightingActivateInactivateDialog() {
    this.zoneLightingActivateInactivateDialogVisible = false;
  }

  closeTrailerLightCheck() {
    this.trailerLightCheckDialogVisible = false;
  }

  handleTrailerLightCheck(command: string) {
    this.closeTrailerLightCheck();
    let observable: Observable<any>;
    let updateStatusFlag = true;

    if (command === 'Start') {
      observable = this.commandsService.trailerLightCheckActivationStart(this.selectedVin);
    } else if (command === 'Stop') {
      observable = this.commandsService.trailerLightCheckActivationStop(this.selectedVin);
    } else if(command === 'Last Command Status') {
      observable = this.commandsService.trailerLightCheckActivationCommandStatus(this.selectedVin, this.trailerLightCheckLastCommandId);
      updateStatusFlag = false;
    }

    this.runCommand("Trailer_Light_Check - " + command, observable, updateStatusFlag);
  }

  checkMissingPlugAndChargePackageCode() {
    this.plugAndChargePackageCode.isMissing = /^\s*$/.test(this.plugAndChargePackageCode.value) || this.plugAndChargePackageCode.value == null;
    let formField = {plugAndChargePackageCode: this.plugAndChargePackageCode} as Record<string, FormField>;

    if (this.plugAndChargePackageCode.isMissing) {
      showMissingFieldsToast(this.messageService, formField);
    }
  }

  handleSubmitPlugAndCharge() {
    this.checkMissingPlugAndChargePackageCode();
    if (!this.plugAndChargePackageCode.isMissing) {
      this.plugAndChargeDialogVisible = false;
      this.runCommand("Plug_And_Charge_PaaK_Status", this.commandsService.paakPlugAndChargeStatus(this.selectedVin, this.plugAndChargePackageCode.value), false);
    }
  }

  closePlugAndCharge() {
    this.plugAndChargePackageCode.value = 'EVPC_PK00200';
    this.plugAndChargePackageCode.isMissing = false;
  }

  handleClick(selectedSubMenu: string) {
    let observable: Observable<any>;
    let typeRequest: TypeRequest;
    let heatCoolRequest: CommandRequest;
    let updateStatusFlag: boolean = false;
    let smartChargeOptInOptOutRequest: SmartChargeOptInOptOutRequest;

    switch(selectedSubMenu) {
      case 'List_Vehicles_Permissions':
        observable = this.commandsService.listVehiclePermissions(this.selectedVin);
        break;
      case 'Authorize_TMC_Vin': {
        this.authorizeTMCVinDialogVisible = true;
        return;
      }
      case 'Request_Access_to_Vin':
        observable = this.commandsService.requestVinAccess(this.selectedVin);
        break;
      case 'Door_Lock':
        typeRequest = {
          type: Type.LOCK,
        } as TypeRequest;
        observable = this.commandsService.doorLock(this.selectedVin, typeRequest);
        updateStatusFlag = true;
        break;
      case 'Door_Unlock':
        typeRequest = {
          type: Type.UNLOCK,
        } as TypeRequest;
        observable = this.commandsService.doorUnLock(this.selectedVin, typeRequest);
        updateStatusFlag = true;
        break;
      case 'Remote_Start':
        typeRequest = {
          type: Type.REMOTE_START
        } as TypeRequest;
        observable = this.commandsService.remoteStart(this.selectedVin, typeRequest);
        updateStatusFlag = true;
        break;
      case 'Cancel_Start':
        typeRequest = {
          type: Type.CANCEL_REMOTE_START
        } as TypeRequest;
        observable = this.commandsService.remoteCancelStart(this.selectedVin, typeRequest);
        updateStatusFlag = true;
        break;
      case 'Telemetry_Settings': {
        this.telemetrySettingsDialogVisible = true;
        return;
      }
      case 'Status_Update':
        typeRequest = {
          type: Type.STATUS_UPDATE
        } as TypeRequest;
        observable = this.commandsService.statusUpdate(this.selectedVin, typeRequest);
        break;
      case 'Heat_ON':
        heatCoolRequest = {
          type: Type.HEATING,
          properties: {
            setting: SettingType.ON
          }
        } as CommandRequest;
        observable = this.commandsService.heatONOFF(this.selectedVin, heatCoolRequest);
        updateStatusFlag = true;
        break;
      case 'Heat_OFF':
        heatCoolRequest = {
          type: Type.HEATING,
          properties: {
            setting: SettingType.OFF
          }
        } as CommandRequest;
        observable = this.commandsService.heatONOFF(this.selectedVin, heatCoolRequest);
        updateStatusFlag = true;
        break;
      case 'Cool_ON':
        heatCoolRequest = {
          type: Type.COOLING,
          properties: {
            setting: SettingType.ON
          }
        } as CommandRequest;
        observable = this.commandsService.coolONOFF(this.selectedVin, heatCoolRequest);
        updateStatusFlag = true;
        break;
      case 'Cool_OFF':
        heatCoolRequest = {
          type: Type.COOLING,
          properties: {
            setting: SettingType.OFF
          }
        } as CommandRequest;
        observable = this.commandsService.coolONOFF(this.selectedVin, heatCoolRequest);
        updateStatusFlag = true;
        break;
      case 'SC_Activate': {
        this.scActivateDialogVisible = true;
        return;
      }
      case 'SC_OptIn': {
        smartChargeOptInOptOutRequest = {
          isOptedIn: true
        } as SmartChargeOptInOptOutRequest;
        observable = this.commandsService.smartChargeOptInOptOut(this.selectedVin, smartChargeOptInOptOutRequest);
        updateStatusFlag = true;
        break;
      }
      case 'SC_OptOut': {
        smartChargeOptInOptOutRequest = {
          isOptedIn: false
        } as SmartChargeOptInOptOutRequest;
        observable = this.commandsService.smartChargeOptInOptOut(this.selectedVin, smartChargeOptInOptOutRequest);
        updateStatusFlag = true;
        break;
      }
      case 'SC_Min_SOC': {
        this.scMinSOCDialogVisible = true;
        return;
      }
      case 'SC_Get_Bidirectional_Settings': {
        observable = this.commandsService.getBidirectionalSettings(this.selectedVin);
        break;
      }
      case 'SC_Save_Bidirectional_Settings': {
        this.scSaveBDSettingDialogVisible = true;
        return;
      }
      case 'Get_Battery_Threshold':
        observable = this.commandsService.evRetrieveBatteryThreshold(this.selectedVin);
        break;
      case 'Save_Battery_Threshold':
        this.saveBatteryThresholdDialogVisible = true;
        return;
      case 'Inactivate_Battery_Threshold':
        this.showInactivateBatteryThresholdConfirmDialog();
        return;
      case 'Toggle_Departure_Times':
        this.toggleDepartureTimesDialogVisible = true;
        return;
      case 'CEVS_Departure_Times':
        this.retrieveDepartureTimes();
        return;
      case 'Global_Charge':
        this.globalChargeDialogVisible = true;
        return;
      case 'VPOI_Plug_Status':
        this.getVPOIPlugStatus();
        return;
      case 'VPOI_Charge_Locations':
        this.getVPOICommandResponeList('VPOI_Charge_Locations');
        return;
      case 'VPOI_Get_Saved_Locations':
        this.getVPOICommandResponeList('VPOI_Get_Saved_Locations');
        return;
      case 'VPOI_Get_Unsaved_Locations':
        this.getVPOICommandResponeList('VPOI_Get_Unsaved_Locations');
        return;
      case 'VPOI_Get_Last_Status':
        observable = this.commandsService.getLastStatus(this.selectedVin,this.vpoiCorrelationId);
        break;
      case 'Get_All_Geofences':
        observable = this.commandsService.geofenceGetAllGeofences(this.selectedVin);
        break;
      case 'Get_Geofence_by_Fence_ID':
        this.geofenceGetByFenceIdDialogVisible = true;
        return;
      case 'Create_Geofence':
        this.geofenceCreateDialogVisible = true;
        return;
      case 'NearBy_GS_by_Location':
        this.stationsByLocationDialogVisible = true;
        return;
      case 'GS_by_LocationId':
        this.stationsByLocationIdDialogVisible = true;
        return;
      case 'FB5_Zone_Lighting_On_Off':
        this.zoneLightingOnOffDialogVisible = true;
        return;
      case 'FB5_Zone_Lighting_Activate_Inactivate':
        this.zoneLightingActivateInactivateDialogVisible = true;
        return;
      case 'FB5_Trailer_Light_Check':
        this.trailerLightCheckDialogVisible = true;
        return;
      case 'FB5_PttB_Update_Status':
        observable = this.commandsService.updatePttbStatus(this.selectedVin);
        updateStatusFlag = true;
        break;
      case 'FB5_Plug_and_Charge':
        this.plugAndChargeDialogVisible = true;
        return;
      case 'FB5_RSOA_Halt_Command':
        observable = this.commandsService.haltRsoAlerts(this.selectedVin);
        break;
      case 'Authorization_Status_Change':
        this.authorizationStatusChangeDialogVisible = true;
        return;
      case 'Device_Diagnostic':
        this.deviceDiagnosticDialogVisible = true;
        return;
      case 'Soft_Reset_Command':
        this.softResetDialogVisible = true;
        return;
      case 'Validate_Vehicle_Claim':
        this.oemRequest = {
          type: 'validateVehicleClaim',
          properties: {},
          clientId: this.auCredentials.clientId,
          clientSecret: this.auCredentials.clientSecret
        } as OEMRequest
        observable = this.commandsService.oemCommands(this.selectedVin,this.oemRequest);
        break;
      case 'Module_Discovery':
        this.oemRequest = {
          type: 'moduleDiscovery',
          properties: {},
          clientId: this.auCredentials.clientId,
          clientSecret: this.auCredentials.clientSecret
        } as OEMRequest
        observable = this.commandsService.oemCommands(this.selectedVin,this.oemRequest);
        break;
      case 'Master_Reset_Command':
        this.oemRequest = {
          type: 'masterReset',
          properties: {},
          clientId: this.auCredentials.clientId,
          clientSecret: this.auCredentials.clientSecret
        } as OEMRequest
        observable = this.commandsService.oemCommands(this.selectedVin, this.oemRequest);
        break;
      case 'Get_Module_Property':
        observable = this.commandsService.oemModuleProperty(this.selectedVin, this.auCredentials);
        break;
      case 'Get_Vehicle_Command_Status':
        this.vehicleCommandStatusDialogVisible = true;
        return;
      case 'Get_OEM_Command_Status':
        this.oemCommandStatusDialogVisible = true;
        return;
    }

    this.runCommand(selectedSubMenu, observable, updateStatusFlag);
  }

  runCommand(selectedSubMenu: string, observable: Observable<any>, updateStatusFlag: boolean) {
    let commandId = "";
    let retries = 7;
    let logMessage = Date() + '\n' +
      selectedSubMenu + ' sent for: ' + this.selectedVin + ' (' + this.selectedVinNickname + ')\n' +
      'Command: ' + selectedSubMenu;
    this.loading = true;

    observable.subscribe((response: TmcResponse) => {
      logMessage += '\n' + JSON.stringify(response);
      if (updateStatusFlag && response && response.responseData) {
        if ((selectedSubMenu.includes('Trailer_Light_Check') && !selectedSubMenu.includes('Last Command Status')) || selectedSubMenu === 'FB5_PttB_Update_Status') {
          commandId = response.responseData['commandId'];
        } else {
          commandId = response.responseData['id'];
        }
      } else {
        commandId = '';
      }
      if (selectedSubMenu === 'CEVS_Departure_Times - Retrieve') {
        this.setDepartureTimesCalendarDaysList(response);
        this.departureTimesDialogVisible = true;
      } else if (selectedSubMenu.includes('Global_Charge') && !selectedSubMenu.includes('Last Command Status')) {
        this.globalChargeLastCorrelationId = (response.responseData) ? response.responseData['correlationId'] : null;
      } else if (selectedSubMenu.includes('Zone_Lighting_On_Off') && !selectedSubMenu.includes('Last Command Status')) {
        this.zoneLightingOnOffLastCommandId = (response.responseData) ? response.responseData['commandId'] : null;
      } else if (selectedSubMenu.includes('Zone_Lighting_Activate_Inactivate') && !selectedSubMenu.includes('Last Command Status')) {
        this.zoneLightingActivateInactivateLastCommandId = (response.responseData) ? response.responseData['commandId'] : null;
      } else if (selectedSubMenu.includes('Trailer_Light_Check') && !selectedSubMenu.includes('Last Command Status')) {
        this.trailerLightCheckLastCommandId = (response.responseData) ? response.responseData['commandId'] : null;
        // If the original start/stop command returned a status of 200, only poll and print once.
        retries = (response.responseData) ? response.responseData['status'] == 200 ? 1 : retries : retries;
      }
    }, error => {
      logMessage += '\n' + JSON.stringify(error);
      handleError(this.messageService, error, this.router);
    }).add(() => {
      this.loading = false;
      this.results = logMessage + '\n--------------------\n' + this.results;
      if (updateStatusFlag && commandId) {
        this.pollCommandStatusUpdate(commandId, selectedSubMenu, retries);
      }
      sessionStorage.setItem('commandResults', btoa(encodeURIComponent(JSON.stringify(this.results))));
    });
  }

  pollCommandStatusUpdate(commandId: string, selectedSubMenu: string, retries: number) {
    let statusCheckRetries = retries;

    timer(0, 15000)
      .pipe(
        switchMap(() => {
          if (selectedSubMenu.includes('Trailer_Light_Check')) {
            let trailerLightStatusRequest = {
              VehicleStatusQueryData: ["chargingstatus", "Odometer", "TrailerLightCheckStatus", "NonExistantData"]
            } as TrailerLightStatusRequest;
            return this.commandsService.trailerLightCheckStatus(this.selectedVin, trailerLightStatusRequest);
          } else if (selectedSubMenu.includes('FB5_PttB_Update_Status')) {
            return this.commandsService.updatePttbStatusCommandStatus(this.selectedVin, commandId);
          }

          return this.commandsService.commandStatus(this.selectedVin, commandId)
        }),
        takeWhile(response => {
          if (selectedSubMenu.includes('Trailer_Light_Check')) {
            return response.responseData != null && statusCheckRetries > 0;
          } else if (selectedSubMenu === 'FB5_PttB_Update_Status') {
            if (statusCheckRetries != 0 && response.responseData != null && response.responseData['status'] == '200') {
              // If the polling check returns a status of 200, only print that one more time.
              statusCheckRetries = 1;
            }
            return response.responseData != null && statusCheckRetries > 0;
          }
          return this.checkCommandStatusRetry(response.responseData['currentStatus']) && statusCheckRetries > 0;
        })
      )
      .subscribe(response => {
        statusCheckRetries = this.printCommandStatusUpdate(commandId, selectedSubMenu, response, statusCheckRetries);
      }, error => {
        statusCheckRetries = this.printCommandStatusUpdate(commandId, selectedSubMenu, error, statusCheckRetries);
        handleError(this.messageService, error, this.router);
      });
  }
  printCommandStatusUpdate(commandId: string, selectedSubMenu: string, payload: any, statusCheckRetries: number) {
    statusCheckRetries--;
    let commandStatusLogMessage = Date() + '\nStatus sent for ' +
      selectedSubMenu + ' for: ' + this.selectedVin + ' (' + this.selectedVinNickname + ')\n' +
      'Command: Polling Status for ' + selectedSubMenu + '\n' +
      'Command ID: ' + commandId + '\n' + JSON.stringify(payload) +
      '\nScheduling command status check for ' + commandId + ' in 15 seconds. Trying ' + statusCheckRetries + ' more times.';
    this.results = commandStatusLogMessage + '\n--------------------\n' + this.results;
    sessionStorage.setItem('commandResults', btoa(encodeURIComponent(JSON.stringify(this.results))));
    return statusCheckRetries;
  }

  checkCommandStatusRetry(currentStatus: string) : boolean {
    return currentStatus && currentStatus !== 'SUCCESS' && currentStatus !== 'FAILURE' && currentStatus !== 'EXPIRED';
  }

  closeSCMinSOCDialog() {
    this.scMinSOC.value = null;
    this.scMinSOC.isMissing = false;
  }

  handleSCMinSOC() {
    this.checkMissingSCMinSOC();
    let formField = {scMinSOC: this.scMinSOC} as Record<string, FormField>;

    if (this.scMinSOC.isMissing) {
      showMissingFieldsToast(this.messageService, formField);
    } else {
        let smartChargeMinSOC = {
          minSoc: this.scMinSOC.value
        } as SmartChargeMinSOCRequest;
        this.scMinSOCDialogVisible = false;
        this.runCommand("SC_Min_SOC", this.commandsService.smartChargeUpdateMinSOC(this.selectedVin, smartChargeMinSOC), false);
    }
  }

  checkMissingSCMinSOC() {
    this.scMinSOC.isMissing = this.scMinSOC.value == null;
  }

  closeSaveBDSettingDialog() {
    this.saveBDSetting['mode'].value = null;
    this.saveBDSetting['rangeReserve'].value = null;

    this.saveBDSetting['mode'].isMissing = false;
    this.saveBDSetting['rangeReserve'].isMissing = false;
    this.saveBDSetting['vin'].isMissing = false;
  }

  handleSaveBDSetting() {
    this.checkMissingBDSetting('mode');
    this.checkMissingBDSetting('rangeReserve');
    this.checkMissingBDSetting('vin');

    if (Object.values(this.saveBDSetting).every(field => !field.isMissing)) {
      this.scSaveBDSettingDialogVisible = false;
      const bidirectionalSettingsRequest = {
        vin: this.saveBDSetting['vin'].value,
        mode: this.saveBDSetting['mode'].value,
        rangeReserve: this.saveBDSetting['rangeReserve'].value
      } as BidirectionalSettingsRequest;
      this.runCommand("SC_Save_Bidirectional_Settings", this.commandsService.setBidirectionalSettings(this.selectedVin, bidirectionalSettingsRequest), false);
    } else {
      showMissingFieldsToast(this.messageService, this.saveBDSetting);
    }
  }

  checkMissingBDSetting(fieldName: string) {
    this.saveBDSetting[fieldName].isMissing = this.saveBDSetting[fieldName].value == null;
  }

  checkMissingSCActivate(fieldName: string) {
    this.scActivate[fieldName].isMissing = this.scActivate[fieldName].value == null;
  }

  closeSCActivateDialog() {
    this.scActivate['address1'].value = null;
    this.scActivate['address2'].value = null;
    this.scActivate['city'].value = null;
    this.scActivate['state'].value = null;
    this.scActivate['zipcode'].value = null;
    this.scActivate['country'].value = null;
    this.scActivate['minSOC'].value = null;
    this.scActivate['providerName'].value = null;
    this.scActivate['planName'].value = null;

    this.scActivate['address1'].isMissing = false;
    this.scActivate['address2'].isMissing = false;
    this.scActivate['city'].isMissing = false;
    this.scActivate['state'].isMissing = false;
    this.scActivate['zipcode'].isMissing = false;
    this.scActivate['country'].isMissing = false;

    this.providerNameOptions = [];
    this.planNameOptions = [];
  }

  handleSCActivate() {
    this.checkMissingSCActivate('address1');
    this.checkMissingSCActivate('address2');
    this.checkMissingSCActivate('state');
    this.checkMissingSCActivate('city');
    this.checkMissingSCActivate('country');
    this.checkMissingSCActivate('zipcode');

    if (Object.values(this.scActivate).every(field => !field.isMissing)) {
      let SC_Activate = {
        isOptedIn: true,
        minSoc: this.scActivate['minSOC'].value,
        address: {
          address1: this.scActivate['address1'].value,
          address2: this.scActivate['address2'].value,
          city: this.scActivate['city'].value,
          state: this.scActivate['state'].value,
          postalCode: this.scActivate['zipcode'].value,
          country: this.scActivate['country'].value
        } as Address,
        utility: {
          utilityId: this.scActivate['providerName'].value ? this.scActivate['providerName'].value.id : null,
          utilityName: this.scActivate['providerName'].value ? this.scActivate['providerName'].value.name : null,
          utilityPlanId: this.scActivate['planName'].value ? this.scActivate['planName'].value.id : null,
          utilityPlanName: this.scActivate['planName'].value ? this.scActivate['planName'].value.name : null
        } as UtilityDetails
      } as SmartChargeActivateRequest;
      this.scActivateDialogVisible = false;
      this.runCommand("SC_Activate", this.commandsService.smartChargeActivate(this.selectedVin, SC_Activate), false);
    } else {
      showMissingFieldsToast(this.messageService, this.scActivate);
    }
  }

  searchProviderName() {
    this.checkMissingSCActivate('zipcode');
    if (this.scActivate['zipcode'].isMissing) {
      this.messageService.add({severity: 'error', summary: 'Zipcode Missing', detail: "Please enter a zipcode to search for providers"});
    } else {
      this.loading = true;
      this.providerNameOptions = [];
      this.commandsService.getProviders(this.scActivate['zipcode'].value).subscribe((smartChargeUtilityResponse: SmartChargeUtilityResponse) => {
        let dropdownOption: DropdownOption;
        if(smartChargeUtilityResponse?.status === "SUCCESS"){
          smartChargeUtilityResponse.utilities.forEach((provider) => {
            dropdownOption = {
              label: provider.name,
              value: {
                name: provider.name,
                id: provider.id
              }
            };
            this.providerNameOptions.push(dropdownOption);
          });
        }
      }, error => {
        handleError(this.messageService, error, this.router);
      }).add(() => {
        this.loading = false;
      });
    }
  }

  searchPlanName() {
    if (this.scActivate['providerName'].value === null) {
      this.messageService.add({severity: 'error', summary: 'Select a Provider', detail: "Please select a Provider first"});
    } else {
      this.loading = true;
      this.planNameOptions = [];
      this.commandsService.getPlans(this.scActivate['providerName'].value.id).subscribe((smartChargeUtilityResponse: SmartChargeUtilityResponse) => {
        let dropdownOption: DropdownOption;
        if(smartChargeUtilityResponse?.status === "SUCCESS"){
          let plans = smartChargeUtilityResponse.utilities.pop();
          plans.utilityTariffs.forEach((plan) => {
            dropdownOption = {
              label: plan.name,
              value: {
                name: plan.name,
                id: plan.id
              }
            };
            this.planNameOptions.push(dropdownOption);
          });
        }
      }, error => {
        handleError(this.messageService, error, this.router);
      }).add(() => {
        this.loading = false;
      });
    }
  }

  showVideoStreamDialog() {
    this.isVideoStreamVisible = true;
    this.getCameraViews();
  }

  getCameraViews() {
    this.videoStreamViewOptions = [];
    this.loading = true;
    this.commandsService.getCameraViews(this.selectedVin).subscribe((videoStreamResponse: VideoStreamResponse) => {
      let dropdownOption: DropdownOption;
      if(videoStreamResponse?.error === null){
        let views = videoStreamResponse.result.views;
        views.forEach((view) => {
          dropdownOption = {
            label: view.id.toString(),
            value: {
              view: view.id,
              quality: view.quality
            }
          };
          this.videoStreamViewOptions.push(dropdownOption);
        });
      }
    }, error => {
      handleError(this.messageService, error, this.router);
    }).add(() => {
      this.loading = false;
    });
  }

  closeVideoStreamDialog() {
    this.videoStreamViewOptions = [];
    this.videoStreamLink = null;
    this.videoStreamView.value = null;
    this.videoStreamView.isMissing = false;
  }

  startLiveStream() {
    this.checkMissingView();
    if(this.videoStreamView.isMissing){
      this.videoStreamView.isMissing = true;
      this.messageService.add({severity: 'error', summary: 'Error', detail: "Please select a View"});
    } else {
      this.loading = true;
      this.commandsService.startStream(this.selectedVin, this.videoStreamView.value.view , this.videoStreamView.value.quality).subscribe((videoStreamResponse: VideoStreamResponse) => {
        if(videoStreamResponse?.code === undefined){
          if(videoStreamResponse.error === null){
            this.videoStreamId = videoStreamResponse.result.id;
            this.messageService.add({severity: 'info', summary: 'Live Stream Started', detail: 'correlation Id : '+this.videoStreamId});
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: videoStreamResponse.error.messages.toString()});
          }
        } else {
          this.messageService.add({severity: 'error', summary: 'Error', detail: videoStreamResponse.message});
        }
      }, error => {
        handleError(this.messageService, error, this.router);
      }).add(() => {
        this.loading = false;
      });
    }
  }
  input = '';
  getStreamURL() {
    if(this.videoStreamId == null){
      this.messageService.add({severity: 'error', summary: 'Error', detail: "Please start a Stream first"});
    } else {
      this.loading = true;
      this.commandsService.getStreamURL(this.selectedVin, this.videoStreamId).subscribe((videoStreamResponse: VideoStreamResponse) => {
        if(videoStreamResponse?.code === undefined){
          if(videoStreamResponse?.error === null){
            this.videoStreamLink = this.sanitizer.sanitize(SecurityContext.URL, this.input);
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: videoStreamResponse.error.messages.toString()});
          }
        } else {
          this.messageService.add({severity: 'error', summary: 'Error', detail: videoStreamResponse.message});
        }
      }, error => {
        handleError(this.messageService, error, this.router);
      }).add(() => {
        this.loading = false;
      });
    }

  }

  stopLiveStream() {
    this.checkMissingView();
    if(this.videoStreamId == null) {
      this.messageService.add({severity: 'error', summary: 'Error', detail: "No stream started, Please start a Stream first"});
    } else {
      this.loading = true;
      this.commandsService.stopStream(this.selectedVin).subscribe((videoStreamResponse: VideoStreamResponse) => {
        this.videoStreamLink = null;
      }, error => {
        handleError(this.messageService, error, this.router);
      }).add(() => {
        this.loading = false;
        this.videoStreamId = null;
      });
    }
  }

  checkMissingView() {
    this.videoStreamView.isMissing = this.videoStreamView.value == null;
  }

  saveChargeStation(chglocIndex, chargeStation, isUnsaved) {
    this.loading = true;
    if(isUnsaved) {
      chargeStation.chargeProfile = this.defaultChargeProfile[chglocIndex];
    }

    let observable: Observable<any>;
    observable = this.commandsService.updateChargeStation(this.selectedVin, chargeStation);
    observable.subscribe((response: TmcResponse) => {
      let responseData = (response.responseData as unknown as VPOIErrorResponse);
      this.vpoiCorrelationId = responseData.correlationId;
      if(response.code == 200){
        this.messageService.add({severity: 'info', summary: 'Charge Location Updated Successfully. Please close and reopen charge locations to see update.'});
      } else {
        let errorMessage = responseData?.message ? responseData.message : response.message;
        this.messageService.add({severity: 'error', summary: 'Failed to Update Charge Location', detail: errorMessage});
      }
    }, error => {
      handleError(this.messageService, error, this.router);
    }).add(() => {
      this.loading = false;
    });
  }

  deleteChargeStation(chglocIndex, chargeStation) {
    this.loading = true;
    let observable: Observable<any>;
    observable = this.commandsService.deleteChargeStation(this.selectedVin, chargeStation.locationId);
    observable.subscribe((response: TmcResponse) => {
      let responseData = (response.responseData as unknown as VPOIErrorResponse);
      this.vpoiCorrelationId = responseData?.correlationId;
      if(response.code == 200){
        this.messageService.add({severity: 'info', summary: 'Charge Location Deleted Successfully. Please close and reopen charge locations to see update.'});
        this.chargeStationList.splice(chglocIndex, 1);
      } else {
        let errorMessage = responseData?.message ? responseData.message : response.message;
        this.messageService.add({severity: 'error', summary: 'Could not delete Charge Location', detail: errorMessage});
      }
    }, error => {
      handleError(this.messageService, error, this.router);
    }).add(() => {
      this.loading = false;
    });
  }

  getVPOICommandResponeList(selectedMenuOption: string) {
    let observable: Observable<any>;
    if (selectedMenuOption === 'VPOI_Charge_Locations') {
      this.getDefaultChargeProfile(10);
      observable = this.commandsService.getChargeLocation(this.selectedVin);
    } else if (selectedMenuOption === 'VPOI_Get_Unsaved_Locations') {
      this.getDefaultChargeProfile(5);
      observable = this.commandsService.getUnSavedLocation(this.selectedVin);
    } else if (selectedMenuOption === 'VPOI_Get_Saved_Locations') {
      this.getDefaultChargeProfile(5);
      observable = this.commandsService.getSavedLocation(this.selectedVin);
    }

    let logMessage = Date() + '\n' +
      selectedMenuOption + ' sent for: ' + this.selectedVin + ' (' + this.selectedVinNickname + ')\n' +
      'Command: ' + selectedMenuOption;
    this.loading = true;

    observable.subscribe((response: VPOIResponseList) => {
      this.vpoiCorrelationId = response?.correlationId;
      this.chargeStationList = response?.vpoiResponses;
      logMessage += '\n' + JSON.stringify(response);
      if(this.chargeStationList.length>0){
        if (selectedMenuOption === 'VPOI_Charge_Locations'){
          this.chargeStationList = this.chargeStationList.slice(0,10);
          this.chargeStationDialogVisible = true;
        } else if (selectedMenuOption === 'VPOI_Get_Unsaved_Locations') {
          this.chargeStationList = this.chargeStationList.slice(0,5);
          this.unsavedChargeStationDialogVisible = true;
        } else if (selectedMenuOption === 'VPOI_Get_Saved_Locations') {
          this.chargeStationList = this.chargeStationList.slice(0,5);
          this.savedChargeStationDialogVisible = true;
        }
      }
    }, error => {
      logMessage += '\n' + JSON.stringify(error);
      handleError(this.messageService, error, this.router);
    }).add(() => {
      this.loading = false;
      this.results = logMessage + '\n--------------------\n' + this.results;
      sessionStorage.setItem('commandResults', btoa(encodeURIComponent(JSON.stringify(this.results))));
    });

  }

  closeVPOIDialog() {
    this.unsavedChargeStationDialogVisible = false;
    this.savedChargeStationDialogVisible = false;
    this.chargeStationDialogVisible = false;
    this.chargeStationList = [];
    this.defaultChargeProfile = [];
  }

  getVPOIPlugStatus() {
    this.loading = true;
    let observable: Observable<any>;
    let logMessage = Date() + '\n VPOI_Plug_Status sent for: ' + this.selectedVin +
      ' (' + this.selectedVinNickname + ')\n' +
      'Command: VPOI_Plug_Status';
    observable = this.commandsService.vpoiPlugStatus(this.selectedVin);
    observable.subscribe((response: VPOIResponse) => {
      logMessage += '\n' + JSON.stringify(response);
      this.results = logMessage + '\n--------------------\n' + this.results;
      this.vpoiCorrelationId = response?.correlationId;
    }, error => {
      handleError(this.messageService, error, this.router);
    }).add(() => {
      this.loading = false;
    });
  }

  private getDefaultChargeProfile(index: number) {
    for (let i = 0; i < index; i++) {
      this.defaultChargeProfile.push({
        chargeNow: true,
        chargeSchedules: [
          {
            days: 'WEEKDAY',
            chargeWindows: [
              {
                startTime: '00:00',
                endTime: '00:00'
              }, {
                startTime: '00:00',
                endTime: '00:00'
              }
            ],
            desiredChargeLevel: 100
          } as ChargeSchedules, {
            days: 'WEEKEND',
            chargeWindows: [
              {
                startTime: '00:00',
                endTime: '00:00'
              }, {
                startTime: '00:00',
                endTime: '00:00'
              }
            ],
            desiredChargeLevel: 100
          } as ChargeSchedules
        ]
      } as ChargeProfile);
    }
  }

  getChargeLocByVinAndLocID(chargeStation) {
    this.loading = true;
    let observable: Observable<any>;
    let logMessage = Date() + '\n VPOI_Plug_Status sent for: ' + this.selectedVin +
      ' (' + this.selectedVinNickname + ')\n' +
      'Command: VPOI_Plug_Status';
    observable = this.commandsService.getChargeLocationById(this.selectedVin, chargeStation.locationId);
    observable.subscribe((response: VPOIResponse) => {
      if(response.code == undefined || response.code == 200){
        logMessage += '\n' + JSON.stringify(response);
        this.results = logMessage + '\n--------------------\n' + this.results;
        this.vpoiCorrelationId = response?.correlationId;
        this.closeVPOIDialog();
      } else {
        let responseData = (response.responseData as unknown as VPOIErrorResponse);
        this.vpoiCorrelationId = responseData?.correlationId;
        let errorMessage = responseData?.message ? responseData.message : response.message;
        this.messageService.add({severity: 'error', summary: 'Could not get charge Location Information.', detail: errorMessage});
      }
      }, error => {
      handleError(this.messageService, error, this.router);
    }).add(() => {
      this.loading = false;
    });
  }

  checkMissingGSByLocationRequest(inputField: string) {
    if (this.gsByLocationRequest[inputField]) {
      const value = this.gsByLocationRequest[inputField].value;
      this.gsByLocationRequest[inputField].isMissing = /^\s*$/.test(value) || value == null;
    }
  }

  checkMissingGSByLocationIdRequest(inputField: string) {
    if (this.gsByLocationIdRequest[inputField]) {
      const value = this.gsByLocationIdRequest[inputField].value;
      this.gsByLocationIdRequest[inputField].isMissing = /^\s*$/.test(value) || value == null;
    }
  }

  closeStationByLocationDialog() {
    Object.values(this.gsByLocationRequest).forEach(field => field.value = null);
    this.gsByLocationRequest['regionCode'].value = 'NA';
    Object.values(this.gsByLocationRequest).forEach(field => field.isMissing = false);
  }

  closeStationByLocationIdDialog() {
    Object.values(this.gsByLocationIdRequest).forEach(field => field.value = null);
    this.gsByLocationIdRequest['regionCode'].value = 'NA';
    Object.values(this.gsByLocationIdRequest).forEach(field => field.isMissing = false);
  }

  getGSByLocation() {
    Object.keys(this.gsByLocationRequest).forEach(fieldName => this.checkMissingGSByLocationRequest(fieldName));
    if (Object.values(this.gsByLocationRequest).every(field => !field.isMissing)) {
      this.stationsByLocationDialogVisible = false;
      this.runCommand("NearBy_GS_by_Location", this.commandsService.getGSByLocation(this.gsByLocationRequest['latitude'].value, this.gsByLocationRequest['longitude'].value, this.gsByLocationRequest['radius'].value, this.gsByLocationRequest['regionCode'].value), false);
    } else {
      showMissingFieldsToast(this.messageService, this.gsByLocationRequest);
    }
  }

  getGSByLocationId() {
    Object.keys(this.gsByLocationIdRequest).forEach(fieldName => this.checkMissingGSByLocationIdRequest(fieldName));
    if (Object.values(this.gsByLocationIdRequest).every(field => !field.isMissing)) {
      this.stationsByLocationIdDialogVisible = false;
      this.runCommand("GS_by_LocationId", this.commandsService.getGSByLocationId(this.selectedVin, this.gsByLocationIdRequest['locationId'].value, this.gsByLocationIdRequest['regionCode'].value), false);
    } else {
      showMissingFieldsToast(this.messageService, this.gsByLocationIdRequest);
    }
  }

  closeAuthorizationStatusChangeDialog() {
    this.enableTelemetry = null;
  }

  submitAuthorizationStatusChange() {
    if(this.enableTelemetry == null){
      this.messageService.add({severity: 'error', summary: 'Missing Fields', detail: "Please select an option"});
    } else {
      this.authorizationStatusChangeDialogVisible = false;
      this.oemRequest = {
        type: 'authorizationStatusChange',
        properties: {
          enableTelemetry: this.enableTelemetry,
          authorizationMode: "AUTHORIZED"
        } as OEMProperties,
        clientId: this.auCredentials.clientId,
        clientSecret: this.auCredentials.clientSecret
      } as OEMRequest;
      this.runCommand("Authorization_Status_Change", this.commandsService.oemCommands(this.selectedVin, this.oemRequest), false);
    }
  }

  closeDeviceDiagnosticDialog() {
    Object.values(this.deviceDiagnosticRequest).forEach(field => field.value = null);
    Object.values(this.deviceDiagnosticRequest).forEach(field => field.isMissing = false);
  }

  checkMissingDeviceDiagnosticRequest(inputField: string) {
    if (this.deviceDiagnosticRequest[inputField]) {
      const value = this.deviceDiagnosticRequest[inputField].value;
      this.deviceDiagnosticRequest[inputField].isMissing = /^\s*$/.test(value) || value == null;
    }
  }

  submitDeviceDiagnosticCommand() {
    Object.keys(this.deviceDiagnosticRequest).forEach(fieldName => this.checkMissingDeviceDiagnosticRequest(fieldName));
    if (Object.values(this.deviceDiagnosticRequest).every(field => !field.isMissing)) {
      this.deviceDiagnosticDialogVisible = false;
      this.oemRequest = {
        type: 'deviceDiagnostic',
        properties: {
          logType: this.deviceDiagnosticRequest['logType'].value,
          bytestreamUri: this.deviceDiagnosticRequest['byteStreamUri'].value
        } as OEMProperties,
        clientId: this.auCredentials.clientId,
        clientSecret: this.auCredentials.clientSecret
      } as OEMRequest;
      this.runCommand("Device_Diagnostic", this.commandsService.oemCommands(this.selectedVin, this.oemRequest), false);
    } else {
      showMissingFieldsToast(this.messageService, this.deviceDiagnosticRequest);
    }
  }

  closeSoftResetDialog() {
    this.softResetRequest['moduleName'].value = null;
    this.softResetRequest['moduleName'].isMissing = false;
  }

  checkMissingModuleName() {
    if(this.softResetRequest['moduleName'].value == null) {
      this.softResetRequest['moduleName'].isMissing = true;
    }
  }

  submitSoftResetCommand() {
    this.checkMissingModuleName();
    if (!this.softResetRequest['moduleName'].isMissing) {
      this.softResetDialogVisible = false;
      this.oemRequest = {
        type: 'softReset',
        properties: {
          moduleName: this.softResetRequest['moduleName'].value
        } as OEMProperties,
        clientId: this.auCredentials.clientId,
        clientSecret: this.auCredentials.clientSecret
      } as OEMRequest;
      this.runCommand("Soft_Reset_Command", this.commandsService.oemCommands(this.selectedVin, this.oemRequest), false);
    } else {
      showMissingFieldsToast(this.messageService, this.softResetRequest);
    }
  }

  closeVehicleCommandStatus() {
    this.vehicleCmdId.value = null;
    this.vehicleCmdId.isMissing = false;
  }

  checkMissingVehicleCommandId() {
    if(this.vehicleCmdId.value == null) {
      this.vehicleCmdId.isMissing = true;
    }
  }

  getVehicleCommandStatus() {
    this.checkMissingVehicleCommandId();
    if (!this.vehicleCmdId.isMissing) {
      this.vehicleCommandStatusDialogVisible = false;
      this.runCommand("Get_Vehicle_Command_Status", this.commandsService.commandStatus(this.selectedVin, this.vehicleCmdId.value), false);
    } else {
      showMissingFieldsToast(this.messageService, {"Vehicle Cmd Id":this.vehicleCmdId} as Record<string, FormField>);
    }
  }

  closeOEMCommandStatus() {
    this.oemCmdId.value = null;
    this.oemCmdId.isMissing = false;
  }

  checkMissingOEMCommandId() {
    if(this.oemCmdId.value == null) {
      this.oemCmdId.isMissing = true;
    }
  }

  getOEMCommandStatus() {
    this.checkMissingOEMCommandId();
    if (!this.oemCmdId.isMissing) {
      this.oemCommandStatusDialogVisible = false;
      this.runCommand("Get_OEM_Command_Status", this.commandsService.oemCommandStatus(this.selectedVin, this.oemCmdId.value, this.auCredentials), false);
    } else {
      showMissingFieldsToast(this.messageService, {"Vehicle Cmd Id":this.oemCmdId} as Record<string, FormField>);
    }
  }
}
