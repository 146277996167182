<div class="component-frame">
  <div id="vins-contents">
    <div id="test-vin-form">
      <label id="test-vin-label">Type VIN Number to Test</label>
      <input type="text" pInputText id="test-vin-input" [(ngModel)]="formFields['testVin'].value"
             class="control-rounded control-border"
             [ngClass]="{'field-error' : formFields['testVin'].isMissing || formFields['testVin'].isInvalid}"
             (focusout)="checkInvalidVin()"/>
      <button pButton label="Submit" id="submit-button" class="control-rounded" (click)="handleSubmit()"></button>
    </div>
    <button *ngFor="let vin of vinsList; let index=index" pButton class="control-rounded vin-list-button" (click)="handleVinClick(index)">
      <span class="vin-list-label">{{vin.nickName + ' - ' + vin.vin}}</span>
      <img class="vin-list-image" src="{{vin.image}}" alt="VIN image">
    </button>
  </div>
  <div class="progress-spinner" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>
<p-toast [preventOpenDuplicates]="true"></p-toast>
