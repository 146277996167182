import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {ConfirmationService, MessageService} from "primeng/api";
import {EventService} from "./shared/event.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class AppComponent {
  title = 'mobileapp-simulator-ui';
  userName = '';
  selectedVin = '';
  isLoggedIn = false;
  constructor(private router: Router,
              private messageService: MessageService,
              private  eventService: EventService) {
  }

  ngOnInit(): void {
    this.eventService.vinEvent.subscribe(vin => {
      this.selectedVin = vin ? " " + vin: '';
    });

    this.eventService.nameEvent.subscribe(userName => {
      this.userName = userName;
    });

    this.eventService.loginEvent.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  handleClick(path: string) {
    if(path === 'manage-profile'){
      path = path +this.router.url;
    }
    this.router.navigate([path])
      .then(null, error => {
        const message = 'There was an issue navigating to /' + path + '.';
        this.messageService.add({severity: 'error', summary: 'Navigation Error', detail: message + '\n\n' + error});
      });
  }

  handleLogOut() {
    this.eventService.loginEvent.emit(false);
    sessionStorage.removeItem('loginUser');
    sessionStorage.removeItem('selectedVin');
    sessionStorage.removeItem('commandResults');
    sessionStorage.removeItem('isAULogin');
    this.selectedVin = '';
    this.userName = '';
    this.handleClick('login');
  }
}
