import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {


	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
			setHeaders: {
				'Authorization': 'Bearer ' + sessionStorage.getItem('encodedAccessToken'),
				'Content-Type': 'application/json'
			}
		});
		return next.handle(request);
	}
}
